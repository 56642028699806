import React from 'react';
import {Link,NavLink} from 'react-router-dom';

function Nav(){
    var sessionUser = localStorage.getItem("userId");
    return(
        <>
        <header>
        <div className="navigation">
        <div className="nav-container">
            <div className="brand">
            <Link className="active" to={"/"}><img src="/newsite/assest/images/logo.png"/></Link>
            </div>
            <nav>
            <div className="nav-mobile"><a id="nav-toggle" href="#!"><span></span></a></div>
            <ul className="nav-list">
                
                {
                //!sessionUser ? <li><NavLink to="/product" className="active">Product</NavLink></li> : null  
                !sessionUser ? <li><NavLink to="/grabar-webcam" className="active">Grabar Webcam</NavLink></li> : null
                
                }
                {
                // !sessionUser ? <li><NavLink to="/usecase" className="active">Use Cases</NavLink></li> : null
                !sessionUser ? <li><NavLink to="/grabar-pantalla" className="active">Grabar Pantalla</NavLink></li> : null 
                
                }
                {
                //!sessionUser ? <li><NavLink to="/aboutus" className="active">About Us</NavLink></li> : null 
                
                }
                {
                sessionUser ? <li><NavLink to={'/recording'} className="active">Crear grabación</NavLink></li> : null 
                
                }
                
                
                {
                sessionUser ? <li><NavLink to={'/getvideos'}  className="active">Mis videos</NavLink></li> : null 
                
                }
            </ul>
            <ul className="nav-list">
                {
                   // !sessionUser ? <li><Link to="/contactSales" >Contact Sales</Link></li> : null 
                    
                }
                {
                !sessionUser ? <li><Link to={'/login'} >Iniciar sesión</Link></li> : null 
                
                }
                
                {
                !sessionUser ? <li><Link to={'/register'} className="bt01">Pruébalo gratis</Link></li> : null 
                
                }
                {
                    sessionUser ? <li><Link to={'/logout'} >Cerrar sesión</Link></li> : null 
                
                }
                {
                    sessionUser ? <li><Link to={'/profile'} >Perfil</Link></li> : null 
                
                }

            </ul>
            </nav>
        </div>
        </div>
  </header>

        
        </>
    );

}

export default Nav;