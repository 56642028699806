import React from 'react';
import {Link} from 'react-router-dom';
import Nav from './Nav';
import Footer from './Footer';
import Slider from "react-slick";
import Faq from './Faq';


function NextArrow(props) {
  const { onClick } = props;
  return (
    <span class="next_arrow" onClick={onClick}><i class="fa fa-angle-right" aria-hidden="true"></i></span> 
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <span class="priv_arrow" onClick={onClick}><i class="fa fa-angle-left" aria-hidden="true"></i></span>
  );
}

function UseCase(){

    let sliderSettings = {
        prevArrow:<PrevArrow />,
         nextArrow:<NextArrow/>,
         slidesToShow: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };


    return(
        <>
       <Nav/>
          <section className="vid-main">
            <div className="container">
              <div className="row">
                <div className="col-md-6 pull-right">
                  <div className="video">
                    <img src="/newsite/assest/images/vid-screen.png"/>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text">
                    <h1> Software de Grabar de pantalla <span>#1</span></h1>
                    <small>Graba pantalla, cámara web o ambas al mismo tiempo.</small>
                    <Link to={'/recording'}><i className="fa fa-circle" aria-hidden="true"></i> Graba Pantalla Ahora</Link>
                  </div>
                </div>

              </div>
            </div>
          </section>

        <section className="user-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center user-guid-head">
                    <h2>Cómo Grabar Pantalla</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="box-bg box-num-pos">
                    <div className="box-num">
                      <span>1</span>
                    </div>
                    <strong>Haz clic en "Grabar" y selecciona "Pantalla"</strong>
                    <p>Tu grabación de pantalla comenzará automáticamente</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="box-bg box-head-clr box-num-pos user-guid-mt">
                    <div className="box-num-cus">
                      <span>2</span>
                    </div>
                    <strong>Personaliza</strong>
                    <p>Establece tus preferencias de calidad de grabación de pantalla</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="box-bg box-num-pos user-mtb">
                    <div className="box-num">
                      <span>3</span>
                    </div>
                    <strong>¡Comparte!</strong>
                    <p>¡Envía tu grabación de pantalla a donde sea!</p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="user-btn text-center mtb-50">
                    <a href=""> <i className="fa fa-play-circle" aria-hidden="true"></i> Graba Pantalla Ahora</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="screen-back">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center user-guid-head">
                    <h2>Ver el Tutorial</h2>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="tutorial-img">
                    <img className="img-w" src="/newsite/assest/images/recoding_tutorial_1.png" alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="capture-bg mt-88">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="capture-head">
                    <div className="drop-point">
                    </div>
                    <div className="drop-circle"></div>
                    <h2>Graba pantalla en línea, al instante</h2>
                    <p>Grabar Video facilita la grabación de tu pantalla. Ya sea que envíes un tutorial explicativo, un 
mensaje de video amigable o mejores tus correos electrónicos, ¡agregar una grabación de 
pantalla con nuestro software en línea gratuito y fácil traerá una sonrisa a la cara de tu 
destinatario!</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="traingle_shape">
            <div className="container">
              <div className="mtb-30">
                <div className="row">
                  <div className="col-md-12">
                    <div className="screen-rec">
                      <h2>Comienza tu grabación de pantalla</h2>
                    </div>
                  </div>
                </div>
                <div className="online-text online-pos">
                  <div className=" online-flex">
                    <div className="num-look mt-45 text-c">
                      <span>1</span>
                      <ul>
                        <li className="dot_1">
                          <img src="/newsite/assest/images/dot1.png" alt=""/>
                        </li>
                        <li className="dot_2">
                          <img src="/newsite/assest/images/dot2.png" alt=""/>
                        </li>
                        <li className="dot_3">
                          <img src="/newsite/assest/images/dot3.png" alt=""/>
                        </li>
                      </ul>
                    </div>
                    <div className="online-para online-screen mt-30">
                      <h2 className="text-c">Haz clic en "Grabar ahora"</h2>
                      <p className="text-c">No es necesario instalar complementos ni software adicional. Grabar Video ya 
es compatible con la cámara y la pantalla de cualquier dispositivo.</p>
                    </div>
                  </div>
                  <div className=" online-flex">
                    <div className="num-look mt-60 text-c">
                      <span>2</span>
                      <ul>
                        <li className="dot_1">
                          <img src="/newsite/assest/images/dot1.png" alt=""/>
                        </li>
                        <li className="dot_2">
                          <img src="/newsite/assest/images/dot2.png" alt=""/>
                        </li>
                        <li className="dot_3">
                          <img src="/newsite/assest/images/dot3.png" alt=""/>
                        </li>
                      </ul>
                    </div>
                    <div className="online-para2 online-screen mt-35">
                      <h2 className="text-c">Selecciona tu modo de grabación</h2>
                      <p className="text-c">Selecciona tu pantalla como la opción de video</p>
                    </div>
                  </div>
                  <div className="online-flex">
                    <div className="num-look mt-50 text-c">
                      <span>3</span>
                    </div>
                    <div className="online-para3 online-screen mt-text">
                      <h2 className="text-c">Deja de grabar y guarda</h2>
                      <p className="text-c">Tu captura de pantalla se guardará automáticamente en tu cuenta, donde podrás
compartir o descargar el archivo fácilmente.</p>
                    </div>
                  </div>
                </div>
                
              
                
                <div className="bg-design"></div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-img">
                    <img className="img-w" src="/newsite/assest/images/screen_recording.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-head">
                    <h2>Crea una grabación de pantalla desde cualquier lugar</h2>
                    <p>Nuestro software de grabación de pantalla se puede utilizar en cualquier lugar, en cualquier 
momento y en cualquier dispositivo. Puedes capturar diferentes resoluciones de video y 
agregar otros elementos de marca personalizables según sea necesario.</p>
                    <a href="" className="btn-explore">Graba Pantalla Ahora</a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="slider">
                <div className="row ">
                  
                  
                  <Slider {...sliderSettings}>
                    <div className="col-md-12 ">
                      <div className="testi-w">
                        <div className="test-img">
                          <div className="testimonial-img">
                            <img src="/newsite/assest/images/img-slider.png" alt=""/>
                          </div>
                          <div className="content testimonial">
                            <h2>Paquete de video todo en uno para tu contenido</h2>
                            <span className="under-line">Screen Recorder</span>
                            <p>Crea videos fácilmente, guarda contenido y distribuye videos personalizados desde cualquier 
lugar.</p>
                            <a href="" className="btn-explore">Graba Pantalla Ahora</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 ">
                      <div className="testi-w">
                        <div className="test-img">
                          <div className="testimonial-img">
                            <img src="/newsite/assest/images/img-slider.png" alt=""/>
                          </div>
                          <div className="content testimonial">
                            <h2>All-in-One Video Suite For Your Video Content</h2>
                            <span className="under-line">Screen Recorder</span>
                            <p>Best-in-class features, value for money, and compatibility makes pinevideo recoder the best tool
                              for
                              making screen videos for your content</p>
                            <a href="" className="btn-explore">Graba Pantalla Ahora</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Slider>
                  


                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-img screen-recorder2">
                    <img className="img-w" src="/newsite/assest/images/screen-recorder2.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-head">
                    <h2>Grabar pantalla desde cualquier lugar</h2>
                    <p>¿Estás interesado en grabar solo tu pantalla o cámara web? Ambas cosas se pueden lograr 
fácilmente a través del software de grabación de cámara web y el software de grabación de 
pantalla.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <Faq/>
          
          <Footer/>
        </>
    );
}
export default UseCase;