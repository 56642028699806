import react from 'react';
import {Redirect} from 'react-router-dom';
import { useGoogleLogout } from 'react-google-login';

import ClientId from "./utils/ClientId";

const clientId =ClientId;

  
function Logout(){
     localStorage.clear();
     const { signOut, loaded } = useGoogleLogout({
          clientId
          
     });  
     signOut();
     return <Redirect to = "home"></Redirect> 
}
export default Logout;