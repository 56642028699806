import React,{ useState } from 'react';
import swal from 'sweetalert';
import { useHistory,Link,Redirect } from "react-router-dom";
import Nav from './Nav';
import ApiUrl from "./utils/ApiUrl";

var crypto = require('crypto');

var algorithm = 'aes256'; // or any other algorithm supported by OpenSSL
var key = 'password';

const Register = () => {
    const [fNameValue, setterFName ] = useState('');
    const [lNameValue, setterlName ] = useState('');
    const [emailValue, setterEmail ] = useState('');
    const [passwordValue, setterPassword ] = useState('');
    const [cnfrmPasswordValue, setterCnfPassword ] = useState('');
    const history = useHistory();
    
    var sessionUser = localStorage.getItem("userId");
     //* change the value of of text field and set the value in new variable */
     const setFirstName  = (event) =>{
        setterFName(event.target.value);   
    }
    const setLastName  = (event) =>{
        setterlName(event.target.value);   
    }
    const setEmail  = (event) =>{
        setterEmail(event.target.value);   
    }
    const setPassword  = (event) =>{
        setterPassword(event.target.value);   
    }
    const setCnfPassword  = (event) =>{
        setterCnfPassword(event.target.value);   
    }


    

    const submitRegister = (event) =>{
        event.preventDefault();
         
        if(fNameValue !=""){
        
            if (lNameValue != ""){
                 
                if(emailValue !=""){
    
                    if(passwordValue !=""){
                         
                        if(cnfrmPasswordValue !=""){
                                
                            if (passwordValue == cnfrmPasswordValue){
                                
                                var cipher = crypto.createCipher(algorithm, key);  
                                var encrypted = cipher.update(passwordValue, 'utf8', 'hex') + cipher.final('hex');

                                const requestOptions = {
                                    method: 'post',
                                    body: JSON.stringify({ "fname":fNameValue,"lname":lNameValue,"email":emailValue,"password":encrypted}),
                                    headers: {
                                        Accept: 'application/json',
                                        // crossDomain : true,
                                        'Content-Type': 'application/json'
                                        
                                    } 
                                   
                                }
                                fetch(ApiUrl+"api/registerUser", requestOptions)
                                    .then(response => response.json())
                                    .then((data) => {
                                       
                                       if(data.status == 200){
                                            var insertID = data.data.insertId;
                                            swal("Thanks! Your details has been submitted", "", "success");
                                            // setterFName('');
                                            // setterlName('');
                                            // setterEmail('');
                                            // setterPassword('');
                                            // setterCnfPassword('');
                                            let userEmail = emailValue;
                                            let userName = fNameValue+' '+lNameValue;   
                                            localStorage.setItem("userId",insertID)
                                            localStorage.setItem("userEmail",userEmail)
                                            localStorage.setItem("userName",userName)
                                            history.push("/recording");
                                        }
                                        else{
                                            swal(data.msg, "", "error");
                                        }
                                        
                                    });
    
                            }else{
                                swal("Please Enter Password And Confirm Password Same", "", "error");
                            }
                        }else{
                            swal("Please Enter Confirm Password value ", "", "error");
                        }
                    }else{
                        swal("Please Enter Password value ", "", "error");
                    }
    
                }else{
                    swal("Please Enter Valid Email-Address", "", "error");   
                }
    
                 
            }else{
                swal("Please Enter Last Name", "", "error");   
            }
    
        }else{
            swal("Please Enter First Name", "", "error"); 
        }
      }
    
      return(
        <>
        {
                sessionUser ? <Redirect to = "/home"></Redirect> : null 
        }
        <section className="login-3">
        <div className="container-fluid">
            <div className="row login-box">
                <div className="col-lg-6 col-md-12 align-self-center pad-0 form-section">
                    <div className="form-inner">
                       
                        <Link to="/" className="logo">
                            <img src="/newsite/assest/images/logo-white.png" alt="logo"/>
                        </Link>
                        
                        <h3>Create An Account</h3>
                        <form onSubmit={ submitRegister }>
                            <div className="form-group form-box">
                                <i className="fa fa-user-o" aria-hidden="true"></i>
                                <input type="text"  className="form-control" placeholder="First Name" aria-label="First Name" onChange={setFirstName} value={fNameValue}/>
                            </div>
                            <div className="form-group form-box">
                                <i className="fa fa-user-o" aria-hidden="true"></i>
                                <input type="text"  className="form-control" placeholder="Last Name" aria-label="Last Name" onChange={setLastName} value={lNameValue}/>
                            </div>
                            <div className="form-group form-box">
                                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                <input type="email" className="form-control" placeholder="Email Address" aria-label="Email Address" onChange={setEmail}  value={emailValue}/>
                            </div>
                            <div className="form-group form-box clearfix">
                                <i className="fa fa-key" aria-hidden="true"></i>
                                <input type="password"  className="form-control" autoComplete="off" placeholder="Password" aria-label="Password" onChange={setPassword} value={passwordValue}/>
                            </div>
                            <div className="form-group form-box clearfix">
                                <i className="fa fa-key" aria-hidden="true"></i>
                                <input type="password"  className="form-control" autoComplete="off" placeholder="Confirm Password" aria-label="Confirm Password" onChange={setCnfPassword} value={cnfrmPasswordValue}/>
                            </div>
                            <div className="checkbox form-group form-box">
                                <div className="form-check checkbox-theme">
                                    <input className="form-check-input" type="checkbox" value="" id="rememberMe" required/>
                                    <label className="form-check-label" htmlFor="rememberMe">
                                        I agree to the <a href="#"> &nbsp;terms of service</a>
                                    </label>
                                </div>
                            </div>
                            <div className="form-group mb-0 clearfix">
                                <button type="submit" className="btn-md btn-theme float-start">Register</button>
                            </div>
                            </form>
                            {/* <div className="extra-login clearfix">
                                <span>Or Login With</span>
                            </div>
                            <div className="clearfix"></div>
                            <ul className="social-list">
                                <li><a href="#" className="facebook-color"><i className="fa fa-facebook facebook-i"></i><span>Facebook</span></a></li>
                                <li><a href="#" className="twitter-color"><i className="fa fa-twitter twitter-i"></i><span>Twitter</span></a></li>
                                <li><a href="#" className="google-color"><i className="fa fa-google google-i"></i><span>Google</span></a></li>
                            </ul> */}
                        
                        <div className="clearfix"></div>
                        <p>Already a member? <Link to="/login">Login here</Link></p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 bg-color-15 pad-0 none-992 bg-img clip-home"></div>
            </div>
        </div>
    </section>

        </>
    );

}

export default Register;