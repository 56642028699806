import React from 'react';
import {Link} from 'react-router-dom';
const Faq = () => {
    return(
        <>
        <section>
            <div className="container">
              <div className="query">
                <h2>Preguntas frecuentes</h2>
                <p>Nuestras preguntas más comunes - Explicadas.</p>
              </div>
              <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        ¿Puedo grabar cualquier pantalla con Grabar Video?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                      <p>Sí, deberías poder crear una grabación de pantalla para cualquier computadora o dispositivo. 
Algunos teléfonos móviles no son compatibles actualmente.
                      </p>

                    </div>
                  </div>
                </div>
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingTwo">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"
                        aria-expanded="false" aria-controls="collapseTwo">
                        ¿Cuáles son algunos buenos usos para crear una grabación de pantalla?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div className="panel-body">
                      <p>
                      Crear grabaciones de pantalla es un método extremadamente efectivo para compartir 
información o explicar ideas complicadas. A menudo, puedes grabar tu pantalla mientras usas 
otro programa (como PowerPoint) para guiar al espectador a través del proceso de 
pensamiento. ¡Cualquier cosa que desees explicar con palabras se puede demostrar más 
fácilmente con una grabación de pantalla!

                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingThree">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree"
                        aria-expanded="false" aria-controls="collapseThree">
                        ¿Este software de captura de pantalla incluye audio?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body">
                      <p>


                      ¡Absolutamente! Puedes hacer grabaciones de pantalla con o sin audio.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingFour">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour"
                        aria-expanded="false" aria-controls="collapseThree">
                        ¿Puedo grabar mi pantalla en una PC y una laptop?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body">
                      <p>
                      Sí, Grabar Video es compatible con PC y puedes crear grabaciones de pantalla en PC. 
También es compatible con todos los computadores.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingFive">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive"
                        aria-expanded="false" aria-controls="collapseFour">
                        ¿Grabar Video es gratis?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body">
                      <p>
                      Si. Puedes realizar grabaciones de pantalla de forma gratuita utilizando nuestro software.
                      </p>
                    </div>
                  </div>
                </div>


                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingSix">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSix"
                        aria-expanded="false" aria-controls="collapseFour">
                         ¿Puedo grabar mi webcam?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseSix" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body">
                      <p>
                      ¡Sí! Para obtener más información sobre cómo grabar cámara web, haz clic aquí.
                      </p>
                    </div>
                  </div>
                </div>

                



              </div>
            </div>
          </section>
          <section className="ready-rec-bg mt-40">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="capture-head">
                  <h2>Grabar Video</h2>
                    {/* <p>Sign Up with Pine Recorder </p> */}
                    <div className="ready-rec">
                    <Link to={'/register'}><i className="fa fa-circle" aria-hidden="true"></i> Grabar un video ahora</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
    );

}

export default Faq;