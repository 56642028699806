import React,{useEffect} from 'react';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import ApiUrl from "./utils/ApiUrl";
function Preview(){

   const {videoid} = useParams();
   const {videoname} = useParams();
   
   useEffect(() => {
      
    updateVideoPreview();
    async function updateVideoPreview() {
       
       const requestOptions = {
           method: 'post',
           body: JSON.stringify({ "video":atob(videoid)}),
           headers: {
               Accept: 'application/json',
               // crossDomain : true,
               'Content-Type': 'application/json'
               
           } 
          
       }
       await fetch(ApiUrl+"api/updateVideoPreview", requestOptions)
           .then(response => response.json())
           .then((data) => {
             //   console.log(data);
               if(data.status === 'success'){

               }
               else{
                   swal("Sorry! Please try again", "", "error");
               }
               
           });

     }
   }, []);

   return (
       <>
         <video width="100%" height="100%" controls>
            <source src={ApiUrl+'video/'+videoname} type="video/mp4"/>
            </video>  
       </>
   )
}
export default Preview;