import logo from './logo.svg';
import React from 'react';

import { Route, Switch } from "react-router-dom";
import { ReactSession } from 'react-client-session';
// import WebRecorder from './WebRecorder';
// import ScreenRecorder from './ScreenRecorder';
// import Media from './Media';
import Register from './Register';

import Login from './Login';
import GetVideos from './GetVideos';
//import 'bootstrap/dist/css/bootstrap.css';
import Protected from './Protected';
import Home from './Home';

import Logout from './Logout';
import Preview from './Preview';
import Recording from './Recording';
import Product from './Product';
import UseCase from './UseCase';
import AboutUs from './AboutUs';
import EditVideo from './EditVideo';
import ContactSales from './ContactSales';
import Profile from './Profile';

import ForgetPassword from './ForgetPassword';
import ContactUs from './Contactus';

class App extends React.Component {
 
  render() {
    ReactSession.setStoreType("localStorage");
    return (
      <>
      <Switch>
         <Route exact path="/" >
           <Home/>
         </Route>
         <Route exact path="/home">
           <Home/>
        </Route>


         <Route exact path="/register">
           <Register/>
        </Route>
        <Route exact path="/login">
           <Login/>
        </Route>

        {/* <Route exact path="/screenrecorder">
          <Protected cmp={ScreenRecorder}/>
        </Route> */}
        
        {/* <Route exact path="/webrecorder">
           <Protected cmp={WebRecorder}/>
        </Route> */}

        <Route exact path="/getvideos">
            <Protected cmp={GetVideos}/>
        </Route>

        <Route exact path="/logout">
            <Logout/>
            
        </Route>

        <Route exact path="/preview/:videoid/:videoname">
            <Preview/>
            
        </Route>


        <Route exact path="/recording">
            <Protected cmp={Recording}/>
        </Route>

        <Route exact path="/grabar-webcam">
            <Product/>
            
        </Route>
        <Route exact path="/grabar-pantalla">
            <UseCase/>
            
        </Route>
        <Route exact path="/aboutus">
            <AboutUs/>
            
        </Route>

        <Route exact path="/contactSales">
            <ContactSales/>
            
        </Route>

        <Route exact path="/profile">
           <Protected cmp={Profile}/>
            
        </Route>

         <Route exact path="/editvideo/:videoid/">
           <Protected cmp={EditVideo}/>
        </Route> 


        <Route exact path="/forgerPassword">
            <ForgetPassword/>
        </Route>

        <Route exact path="/contactus">
            <ContactUs/>
            
        </Route>
        


       </Switch>
      </>
    )
  }


  
}

export default App;
