import Nav from "./Nav";
import React from 'react';
import {Link} from 'react-router-dom';
import Footer from './Footer';
import Slider from "react-slick";
import Faq from "./Faq";

function NextArrow(props) {
    const { onClick } = props;
    return (
      <span class="next_arrow" onClick={onClick}><i class="fa fa-angle-right" aria-hidden="true"></i></span> 
    );
  }
  
  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <span class="priv_arrow" onClick={onClick}><i class="fa fa-angle-left" aria-hidden="true"></i></span>
    );
  }


function Product(){
   
    let sliderSettings = {
        prevArrow:<PrevArrow />,
            nextArrow:<NextArrow/>,
            slidesToShow: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
            ]
        }; 
   
    
   return (
       <>
       <Nav/>
       
       <section className="vid-main">
            <div className="container">
              <div className="row">
                <div className="col-md-6 pull-right">
                  <div className="video">
                    <img src="/newsite/assest/images/vid-screen.png"/>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text">
                    <h1> Software para Grabar Cámara Web <span>#1</span></h1>
                    <small>Graba fácilmente tu cámara web con nuestro software.</small>
                    <Link to={'/recording'}><i className="fa fa-circle" aria-hidden="true"></i> Graba tu cámara web ahora</Link>
                  </div>
                </div>

              </div>
            </div>
          </section>

          <section className="user-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center user-guid-head">
                    <h2>Cómo Grabar con Cámara Web</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="box-bg box-num-pos">
                    <div className="box-num">
                      <span>1</span>
                    </div>
                    <strong>Haz clic en "Grabar" y selecciona "Cámara web"</strong>
                    <p>La grabación de tu cámara web comenzará automáticamente</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="box-bg box-head-clr box-num-pos user-guid-mt">
                    <div className="box-num-cus">
                      <span>2</span>
                    </div>
                    <strong>Personaliza</strong>
                    <p>Establece tus preferencias de calidad de <Link to="/grabar-pantalla">grabación de pantalla</Link></p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="box-bg box-num-pos user-mtb">
                    <div className="box-num">
                      <span>3</span>
                    </div>
                    <strong>¡Comparte!</strong>
                    <p>¡Envía tu <Link to="/grabar-pantalla">grabación de pantalla</Link> a donde sea!</p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="user-btn text-center mtb-50">
                  <Link to={'/register'} > <i className="fa fa-play-circle" aria-hidden="true"></i> Grabar un Cámara Web</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="screen-back">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center user-guid-head">
                    <h2>Ver el Tutorial</h2>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="tutorial-img">
                    <img className="img-w" src="/newsite/assest/images/recoding_tutorial_1.png" alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="capture-bg mt-88">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="capture-head">
                    <div className="drop-point">
                    </div>
                    <div className="drop-circle"></div>
                    <h2>Grabe tu cámara web en línea - al instante </h2>
                    <p>Grabar Video facilita la grabación con tu cámara web. Ya sea que se trate de enviar un tutorial 
explicativo, un mensaje de video amigable o mejorar tus correos electrónicos, ¡crear un video 
de cámara web con nuestro software en línea fácil y gratuito traerá una sonrisa a la cara de tu 
destinatario!</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="traingle_shape">
            <div className="container">
              <div className="mtb-30">
                <div className="row">
                  <div className="col-md-12">
                    <div className="screen-rec">
                      <h2>Comienza la Grabación de tu Cámara Web</h2>
                    </div>
                  </div>
                </div>
                <div className="online-text online-pos">
                  <div className=" online-flex">
                    <div className="num-look mt-45 text-c">
                      <span>1</span>
                      <ul>
                        <li className="dot_1">
                          <img src="/newsite/assest/images/dot1.png" alt=""/>
                        </li>
                        <li className="dot_2">
                          <img src="/newsite/assest/images/dot2.png" alt=""/>
                        </li>
                        <li className="dot_3">
                          <img src="/newsite/assest/images/dot3.png" alt=""/>
                        </li>
                      </ul>
                    </div>
                    <div className="online-para online-screen mt-30">
                      <h2 className="text-c">Haz clic en "Grabar ahora"</h2>
                      <p className="text-c">No es necesario instalar complementos ni software adicional. Grabar Video ya 
es compatible con la cámara y la pantalla de cualquier dispositivo.</p>
                    </div>
                  </div>
                  <div className=" online-flex">
                    <div className="num-look mt-60 text-c">
                      <span>2</span>
                      <ul>
                        <li className="dot_1">
                          <img src="/newsite/assest/images/dot1.png" alt=""/>
                        </li>
                        <li className="dot_2">
                          <img src="/newsite/assest/images/dot2.png" alt=""/>
                        </li>
                        <li className="dot_3">
                          <img src="/newsite/assest/images/dot3.png" alt=""/>
                        </li>
                      </ul>
                    </div>
                    <div className="online-para2 online-screen mt-35">
                      <h2 className="text-c">Selecciona tu modo de grabación</h2>
                      <p className="text-c">Selecciona tu cámara web como la opción de video</p>
                    </div>
                  </div>
                  <div className="online-flex">
                    <div className="num-look mt-50 text-c">
                      <span>3</span>
                    </div>
                    <div className="online-para3 online-screen mt-text">
                      <h2 className="text-c">Termina de grabar y guarda</h2>
                      <p className="text-c">Tu captura de pantalla se guardará automáticamente en tu cuenta, donde podrás
compartir o descargar el archivo fácilmente.</p>
                    </div>
                  </div>
                </div>
                
              
                
                <div className="bg-design"></div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-img">
                    <img className="img-w" src="/newsite/assest/images/screen_recording.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-head">
                    <h2>Crea una grabación de cámara web desde cualquier lugar</h2>
                    <p>Nuestro software de grabación de cámara web se puede usar en cualquier lugar, en cualquier 
momento y en cualquier dispositivo. Puedes capturar diferentes resoluciones de video y 
agregar otros elementos de marca personalizables según sea necesario.</p>
                    <a href="" className="btn-explore">Graba un Cámara Web</a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="slider">
                <div className="row ">
                  
                  
                  <Slider {...sliderSettings}>
                    <div className="col-md-12 ">
                      <div className="testi-w">
                        <div className="test-img">
                          <div className="testimonial-img">
                            <img src="/newsite/assest/images/img-slider.png" alt=""/>
                          </div>
                          <div className="content testimonial">
                            <h2>Paquete de video todo en uno para tu contenido</h2>
                            <span className="under-line">Screen Recorder</span>
                            <p>Realiza fácilmente grabaciones de cámara web, guarda contenido y distribuye videos 
personalizados en cualquier lugar.</p>
                            <a href="" className="btn-explore">Graba un Cámara Web</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 ">
                      <div className="testi-w">
                        <div className="test-img">
                          <div className="testimonial-img">
                            <img src="/newsite/assest/images/img-slider.png" alt=""/>
                          </div>
                          <div className="content testimonial">
                            <h2>All-in-One Video Suite For Your Video Content</h2>
                            <span className="under-line">Grabar un Cámara Web</span>
                            <p>Best-in-class features, value for money, and compatibility makes pinevideo recoder the best tool
                              for
                              making screen videos for your content</p>
                            <a href="" className="btn-explore">Graba un Cámara Web</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Slider>
                  


                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-img screen-recorder2">
                    <img className="img-w" src="/newsite/assest/images/screen-recorder2.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-head">
                    <h2>Graba cámara web desde cualquier lugar</h2>
                    <p>¿Estás interesado en grabar solo tu pantalla o cámara web? Ambas cosas se pueden lograr 
fácilmente a través del software de grabación de cámara web y el software de grabación de 
pantalla.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          


          <section>
            <div className="container">
              <div className="query">
                <h2>Preguntas frecuentes</h2>
                <p>Nuestras preguntas más comunes - Explicadas.</p>
              </div>
              <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        ¿Puedo grabar con cualquier cámara web usando Grabar Video?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                      <p>Sí, deberías poder crear una grabación de cámara web desde cualquier tipo de cámara web en 
tu dispositivo. Incluso los teléfonos móviles son compatibles con nuestro software.
                      </p>

                    </div>
                  </div>
                </div>
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingTwo">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"
                        aria-expanded="false" aria-controls="collapseTwo">
                        ¿Cuáles son algunos buenos usos para crear una grabación de cámara web?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div className="panel-body">
                      <p>
                      Crear grabaciones de cámara es un método extremadamente efectivo para compartir 
información o explicar ideas complicadas. A menudo, puedes grabar tu cámara mientras usas 
otro programa (como PowerPoint) para guiar al espectador a través del proceso de 
pensamiento. ¡Cualquier cosa que desees explicar con palabras se puede demostrar más 
fácilmente con una grabación de cámara web!

                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingThree">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree"
                        aria-expanded="false" aria-controls="collapseThree">
                        ¿Este software de captura de pantalla incluye audio?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body">
                      <p>


                      ¡Absolutamente! Puedes hacer grabaciones de pantalla con o sin audio.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingFour">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour"
                        aria-expanded="false" aria-controls="collapseThree">
                        ¿Grabar Video es gratis?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body">
                      <p>
                      Si. Puedes realizar grabaciones de cámara web de forma gratuita utilizando nuestro software.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingFive">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive"
                        aria-expanded="false" aria-controls="collapseFour">
                        ¿Puedo grabar mi pantalla?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body">
                      <p>
                      ¡Sí! Para obtener más información sobre cómo grabar tu pantalla, haz clic aquí.
                      </p>
                    </div>
                  </div>
                </div>
               </div>
            </div>
          </section>
          <section className="ready-rec-bg mt-40">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="capture-head">
                    <h2>Grabar Video</h2>
                    {/* <p>Sign Up with Pine Recorder </p> */}
                    <div className="ready-rec">
                    <Link to={'/register'}><i className="fa fa-circle" aria-hidden="true"></i> Grabar un video ahora</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer/>
       </>
   )
}
export default Product;