import React,{ useState,useEffect } from 'react';
import swal from 'sweetalert';
import Nav from './Nav';
// import { ReactSession } from 'react-client-session';
// import PublicUrl from './Public';
// import Home from "./Home";
import ApiUrl from "./utils/ApiUrl";
import {Link,useHistory} from "react-router-dom";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import Footer from './Footer';

import { Button, Modal } from "react-bootstrap";
import Faq from './Faq';
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";


import {
FacebookShareCount,
HatenaShareCount,
OKShareCount,
PinterestShareCount,
RedditShareCount,
TumblrShareCount,
VKShareCount
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";

const GetVideos = () => {
     const [videos, setVideos] = useState(null);
    
     let sessionUserId = localStorage.getItem("userId");

     const [videoShareUrl,setVideoShareUrl] = useState('');
     const [flagofDatafound,setDataFound] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const history = useHistory();
     let x = 1;

     

     const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric",hour: '2-digit', minute: '2-digit', second: '2-digit'}
        return new Date(dateString).toLocaleDateString(undefined, options)
      }


     useEffect(() => {
      
     getVideos();
     async function getVideos() {
        
        const requestOptions = {
            method: 'post',
            body: JSON.stringify({ "user":sessionUserId}),
            headers: {
                Accept: 'application/json',
                // crossDomain : true,
                'Content-Type': 'application/json'
                
            } 
           
        }
        await fetch(ApiUrl+"api/getVideos", requestOptions)
            .then(response => response.json())
            .then((data) => {
              //   console.log(data);
                if(data.status === 'success'){
                    let videodata = data.data;
                    setVideos(videodata);
                    if(data.data.length>0){
                      setDataFound(true);
                    }
                   
                    $('#example').DataTable();
                }
                else{
                    swal("Sorry! Please try again", "", "error");
                }
                
            });

      }
    }, []);


    function shareUrl(e){
      var url  = e.target.id;
      // navigator.clipboard.writeText(url)
      // swal("Successfully copied !", "", "success");
      setVideoShareUrl(url);
      setShow(true);
    }

    function deleteVideo(e){
       e.preventDefault();
      var videoId = e.target.id;
      swal({
        title: "Are you sure?",
        text: "You will not be able to recover this file!",
        icon: "warning",
        buttons: [
          'No, cancel it!',
          'Yes, I am sure!'
        ],
        dangerMode: true,
      }).then(function(isConfirm) {
        if (isConfirm) {
            const requestOptions = {
              method: 'post',
              body: JSON.stringify({ "video":videoId}),
              headers: {
                  Accept: 'application/json',
                  // crossDomain : true,
                  'Content-Type': 'application/json'
                  
              } 
            
          }
          fetch(ApiUrl+"api/deleteVideo", requestOptions)
              .then(response => response.json())
              .then((data) => {
                //   console.log(data);
                  if(data.status == 200){
                      swal("Successfully Delete", "", "success");
                      $('.videoRow'+videoId).remove();
                      history.push("/getvideos");
                      $('#example').DataTable();
                  }
                  else{
                      swal("Sorry! Please try again", "", "error");
                  }
                  
              });
        } else {
          swal("Cancelled", "Your file is safe :)", "success");
        }
      })


    }

    function copyUrl(e){
      e.preventDefault();
      var url  = e.target.id;
      navigator.clipboard.writeText(url)
      swal("Successfully copied !", "", "success");
  }


    const videoRender = () =>{
      if(flagofDatafound == true){
        return <>
        {videos && (
                        
                        <>
                        {videos.map((video, index) => (
                    <div className={`col-lg-4 col-md-4 tr-align videoRow`+video.id}>
                        <div className="account-bg">
                            {/* <img src="/newsite/assest/images/account_user.png" alt="" /> */}
                            <video className="video_mr get-video-scr" width="250" height="" controls poster={video.video_url != null ? ApiUrl+`video_thumb/`+video.video_thumb : ApiUrl+`video_thumb/no_thumb_found.jpg`}>
                              
                                  <source src={ApiUrl+`video/`+video.video_url} type="video/mp4"/>
                                  </video>
                            <h3>{video.video_title != null ? video.video_title : `Title Not Found`}</h3>
                            <div className="user-clock">
                                <a href=""><i className="fa fa-calendar" aria-hidden="true"></i> 
                                {formatDate(video.created_date)}</a>
                               
                                <a href=""><i className="fa fa-user" aria-hidden="true"></i>{video.total_user_view}
                                </a>
                            </div>
                            <div className="user-share user-mt">
                                <a href="#" onClick={shareUrl} id={window.location.origin+'/preview/'+btoa(video.id)+'/'+video.video_url}> Share</a>
                               
                                <Link to={'/editvideo/'+btoa(video.id)} className="btn-user share-ml">Settings</Link>
                              
                                <a href="#" className="share-ml"  onClick={deleteVideo} id={video.id}> Delete</a>
                                <a href="" className="btn-user mt-10 share-ml" onClick={copyUrl} id={window.location.origin+'/preview/'+btoa(video.id)+'/'+video.video_url}>Copy URL</a>
                                <a href={ApiUrl+`video/`+video.video_url} className="btn-user mt-10 share-ml" id={window.location.origin+'/preview/'+btoa(video.id)+'/'+video.video_url} download={video.video_url} target="_blank">Download</a>
                            </div>

                        </div>
                    </div>
                    ))}
                        
                        </>
                      )}
        
        </>
      }
      else {
          return <> 
          <div className="col-lg-12 col-md-12 tr-align">
                        <div className="account-bg">
                            <h3>No Video Record Found</h3>
                         </div>
                    </div>
         </>
        }
    }


    
    
      return(
        <>
        <Nav/>
        
        <section className="account-top">
        <div className="container">
            <div>
                <div className="pine_video">
                    <h2>Pine Video User Accounts</h2>
                    <p>Record your screen, webcam, or both, then share video messages instantly. Pine Video is an
                        essential tool for
                        team communication.</p>
                </div>
                <div className="row">
                
                    {videoRender()}
                    

                      
                      

                      
                      {/* {videos.length==0 &&
                        <div className="col-lg-12 col-md-12 tr-align">
                        <div className="account-bg">
                            <h3>No Video Record Found</h3>
                         </div>
                    </div>
                      } */}

                      

                      
                       
                      
                    
                </div>
            </div>
        </div>
    </section>

    <section className="ready-rec-bg mt-40">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="capture-head">
                  <h2>Grabar Video</h2>
                    {/* <p>Sign Up with Pine Recorder </p> */}
                    <div className="ready-rec">
                    <Link to={'/register'}><i className="fa fa-circle" aria-hidden="true"></i> Grabar un video ahora</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      
      <Footer/>

        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <FacebookShareButton
            url={videoShareUrl}
            className=""
         >
        <FacebookIcon size={32} round />
        </FacebookShareButton>
        
        <TwitterShareButton url={videoShareUrl}>
           <TwitterIcon size={32} round />
        </TwitterShareButton>
           
         <TelegramShareButton url={videoShareUrl}>
             <TelegramIcon size={32} round />
         </TelegramShareButton>
          
          <WhatsappShareButton url={videoShareUrl}>
             <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          
          <LinkedinShareButton url={videoShareUrl}>
               <LinkedinIcon size={32} round/>

          </LinkedinShareButton>
          
          {/* <PinterestShareButton url={videoShareUrl}>
             <PinterestIcon size={32} round/> 
          </PinterestShareButton>   */}

          <EmailShareButton url={videoShareUrl}>
             <EmailIcon size={32} round/> 
          </EmailShareButton>


        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>




        
         
        </>
    );

}

export default GetVideos;