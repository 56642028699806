import React,{ useState } from 'react';
import swal from 'sweetalert';
// import { ReactSession } from 'react-client-session';
import { useHistory,Redirect  } from "react-router-dom";

import ApiUrl from "./utils/ApiUrl";

import Nav from './Nav';


const ForgetPassword = () => {

    
    const history = useHistory();
   const [emailValue, setterEmail ] = useState('');
    
    /* change the value of of text field and set the value in new variable */
    const setEmail  = (event) =>{
        setterEmail(event.target.value);   
    }
    

    var sessionUser = localStorage.getItem("userId");

        const submitLogin = (event) =>{
            event.preventDefault();
            const requestOptions = {
                method: 'post',
                body: JSON.stringify({"email":emailValue}),
                headers: {
                    Accept: 'application/json',
                    // crossDomain : true,
                    'Content-Type': 'application/json'
                    
                } 
               
            }
            fetch(ApiUrl+"api/ForgetPassword", requestOptions)
                .then(response => response.json())
                .then((data) => {
                    if(data.status == 200){
                        swal("Please check Your Mail, Password Has Been Sent To Your Email", "", "success");
                        history.push("/");
                    }
                    else{
                        swal(data.msg, "", "error");
                    }
                    
                });
    
        }
        
        return(
            <>
            {
                sessionUser ? <Redirect to = "/recording"></Redirect> : null 
            }
            <Nav/>
            <div className="container bg-color">
            <div className=" text-center">
                <div className="row">
                    <div className="col-md-12">
                        <p className="register">Forget Password</p>

                    </div>

                </div>

            </div>
            <form onSubmit={ submitLogin }>
            <div className="">
                <div className="row">
                    <div className="col-md-12">
                        <div className="input-form">
                        <label>Username/Email</label>
                        <input type="Email" className={`login_page text-center`} placeholder="Enter Email" onChange={setEmail} value={emailValue} name="email" id="" required/>
                        </div>
                    </div>
                   
                    <div className="col-md-12 ">
                        <div className="input-form">
                        <input type="submit" value="Submit" className=""/>
                        </div>
                    </div>
             </div>

            </div>
            </form>
            
            
        </div>
             
            </>
        )
    // }
}

export default ForgetPassword;