import React from 'react';
import {Link,NavLink} from 'react-router-dom';
const Footer = () => {
    return(
        <>
        <footer>
            <div className="container">
            <div className="row">
                <div className="col-md-12">
                <ul className="f-social">
                    <li><a href=""><i className="fa fa-facebook-square" aria-hidden="true"></i></a></li>
                    <li><a href=""><i className="fa fa-twitter"></i></a></li>
                    <li><a href=""><i className="fa fa-instagram"></i></a></li>
                    <li><a href=""><i className="fa fa-youtube-play" aria-hidden="true"></i></a></li>
                    <li><a href=""><i className="fa fa-linkedin-square" aria-hidden="true"></i></a></li>
                </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                <div className="copyright">
                    Copyright © 2022 Grabar Video Pvt. Ltd. All Rights Reserved. <a href="#">Privacy Policy</a> | <a
                    href="#">Terms & Conditions</a> | <a href="#">Sitemap</a> | <Link to={'/contactus'} >Contacto</Link>
                </div>
                </div>
             </div>
            </div>
        </footer>
        </>
    );

}

export default Footer;