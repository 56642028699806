import React from 'react';
import {Link} from 'react-router-dom';
import Nav from './Nav';
import Footer from './Footer';
import Slider from "react-slick";
import Faq from "./Faq";

function NextArrow(props) {
  const { onClick } = props;
  return (
    <span className="next_arrow" onClick={onClick}><i className="fa fa-angle-right" aria-hidden="true"></i></span> 
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <span className="priv_arrow" onClick={onClick}><i className="fa fa-angle-left" aria-hidden="true"></i></span>
  );
}

function Home(){
     
      let sliderSettings = {
        prevArrow:<PrevArrow />,
         nextArrow:<NextArrow/>,
         slidesToShow: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };

     return (
        <>
          <Nav/>
          <section className="vid-main">
            <div className="container">
              <div className="row">
                <div className="col-md-6 pull-right">
                  <div className="video">
                    <img src="/newsite/assest/images/vid-screen.png"/>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text">
                    <h1> Software Online para Grabar Vídeo <span>#1</span></h1>
                    <small>Graba fácilmente un video en línea.</small>
                    <Link to={'/register'}><i className="fa fa-circle" aria-hidden="true"></i> Grabar un Video Ahora</Link>
                  </div>
                </div>

              </div>
            </div>
          </section>

        <section className="user-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center user-guid-head">
                    <h2>Cómo grabar un video en línea</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="box-bg box-num-pos">
                    <div className="box-num">
                      <span>1</span>
                    </div>
                    <strong>Grabar un video</strong>
                    <p>Haz clic en "Grabar un video" para comenzar a grabar en línea y de forma 
gratuita</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="box-bg box-head-clr box-num-pos user-guid-mt">
                    <div className="box-num-cus">
                      <span>2</span>
                    </div>
                    <strong>Personaliza</strong>
                    <p>Establece tus preferencias de vídeo</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="box-bg box-num-pos user-mtb">
                    <div className="box-num">
                      <span>3</span>
                    </div>
                    <strong>¡Comparte!</strong>
                    <p>¡Envía tu video a donde debe estar!</p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="user-btn text-center mtb-50">
                    <a href=""> <i className="fa fa-play-circle" aria-hidden="true"></i>Graba Pantalla Ahora</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="screen-back">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center user-guid-head">
                    <h2>Ver el Tutorial</h2>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="tutorial-img">
                    <img className="img-w" src="/newsite/assest/images/recoding_tutorial_1.png" alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="capture-bg mt-88">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="capture-head">
                    <div className="drop-point">
                    </div>
                    <div className="drop-circle"></div>
                    <h2>Graba un video en línea, al instante </h2>
                    <p>Grabar Video facilita la grabación de videos en línea. Ya sea que se trate de enviar un tutorial 
explicativo, un mensaje de video amigable o mejorar tus correos electrónicos, ¡agregar un video
con nuestro software en línea fácil y gratuito traerá una sonrisa a la cara de tu destinatario!</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="traingle_shape">
            <div className="container">
              <div className="mtb-30">
                <div className="row">
                  <div className="col-md-12">
                    <div className="screen-rec">
                      <h2>Comienza tu grabación de video</h2>
                    </div>
                  </div>
                </div>
                <div className="online-text online-pos">
                  <div className=" online-flex">
                    <div className="num-look mt-45 text-c">
                      <span>1</span>
                      <ul>
                        <li className="dot_1">
                          <img src="/newsite/assest/images/dot1.png" alt=""/>
                        </li>
                        <li className="dot_2">
                          <img src="/newsite/assest/images/dot2.png" alt=""/>
                        </li>
                        <li className="dot_3">
                          <img src="/newsite/assest/images/dot3.png" alt=""/>
                        </li>
                      </ul>
                    </div>
                    <div className="online-para online-screen mt-30">
                      <h2 className="text-c">Haz clic en "Grabar ahora"</h2>
                      <p className="text-c">No es necesario instalar complementos ni software adicional. Grabar Video ya 
es compatible con la cámara y la pantalla de cualquier dispositivo.</p>
                    </div>
                  </div>
                  <div className=" online-flex">
                    <div className="num-look mt-60 text-c">
                      <span>2</span>
                      <ul>
                        <li className="dot_1">
                          <img src="/newsite/assest/images/dot1.png" alt=""/>
                        </li>
                        <li className="dot_2">
                          <img src="/newsite/assest/images/dot2.png" alt=""/>
                        </li>
                        <li className="dot_3">
                          <img src="/newsite/assest/images/dot3.png" alt=""/>
                        </li>
                      </ul>
                    </div>
                    <div className="online-para2 online-screen mt-35">
                      <h2 className="text-c">Selecciona tu modo de grabación</h2>
                      <p className="text-c">Decide entre grabar tu cámara web, pantalla o ambas</p>
                    </div>
                  </div>
                  <div className="online-flex">
                    <div className="num-look mt-50 text-c">
                      <span>3</span>
                    </div>
                    <div className="online-para3 online-screen mt-text">
                      <h2 className="text-c">Deja de grabar y guarda</h2>
                      <p className="text-c">Tu video se guardará automáticamente en tu cuenta, donde puedes compartir o 
descargar el archivo fácilmente.</p>
                    </div>
                  </div>
                </div>
                
              
                
                <div className="bg-design"></div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-img">
                    <img className="img-w" src="/newsite/assest/images/screen_recording.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-head">
                    <h2>Crea un video desde cualquier lugar</h2>
                    <p>Nuestro software de creación de videos se puede usar en cualquier lugar, en cualquier 
momento y en cualquier dispositivo. Puedes grabar diferentes resoluciones de video y agregar 
otros elementos de marca personalizables según sea necesario.</p>
                    <a href="" className="btn-explore">Grabar un Video Ahora</a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="slider">
                <div className="row ">
                  
                  
                  <Slider {...sliderSettings}>
                    <div className="col-md-12 ">
                      <div className="testi-w">
                        <div className="test-img">
                          <div className="testimonial-img">
                            <img src="/newsite/assest/images/img-slider.png" alt=""/>
                          </div>
                          <div className="content testimonial">
                            <h2>Paquete de video todo en uno para tu contenido</h2>
                            <span className="under-line">Grabar Video</span>
                            <p>Crea videos fácilmente, guarda contenido y distribuye videos personalizados desde una 
ubicación.</p>
                            <a href="" className="btn-explore">Grabar un Video Ahora</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 ">
                      <div className="testi-w">
                        <div className="test-img">
                          <div className="testimonial-img">
                            <img src="/newsite/assest/images/img-slider.png" alt=""/>
                          </div>
                          <div className="content testimonial">
                            <h2>Paquete de video todo en uno para tu contenido</h2>
                            <span className="under-line">Grabar Video</span>
                            <p>Crea videos fácilmente, guarda contenido y distribuye videos personalizados desde una 
ubicación.</p>
                            <a href="" className="btn-explore">Grabar un Video Ahora</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Slider>
                  


                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-img screen-recorder2">
                    <img className="img-w" src="/newsite/assest/images/screen-recorder2.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-head">
                    <h2>Haz un video desde cualquier lugar</h2>
                    <p>¿Estás interesado en grabar solo tu pantalla o cámara web? Ambas cosas se pueden lograr 
fácilmente a través del software de grabación de cámara web y el software de grabación de 
pantalla.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
         


          <section>
            <div className="container">
              <div className="query">
                <h2>Preguntas frecuentes</h2>
                <p>Nuestras preguntas más comunes - Explicadas.</p>
              </div>
              <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        ¿Qué puedo hacer con Grabar Video?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                      <p>Grabar Video hace que sea muy fácil hacer videos en línea. Nuestro software de grabación de 
video patentado facilita la selección entre grabar tu cámara web, pantalla o ambos. Además, 
una vez que hayas terminado de crear tu video, puedes compartirlo y distribuirlo fácilmente en 
la web.
                      </p>

                    </div>
                  </div>
                </div>
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingTwo">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"
                        aria-expanded="false" aria-controls="collapseTwo">
                        ¿Cómo crear vídeos?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div className="panel-body">
                      <p>
                      ¡Crear videos es fácil! Cualquiera lo puede hacer. La mayoría de los softwares de grabación de 
video pueden ser engorroso y difíciles de usar. Además, una gran cantidad de softwares 
requieren la descarga de programas adicionales a tu computador. Nuestro software gratuito 
vive en la nube, lo que hace que sea rápido y sencillo grabar videos en línea sin ningún 
software adicional de terceros.

                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingThree">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree"
                        aria-expanded="false" aria-controls="collapseThree">
                        ¿Este software de video captura audio?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body">
                      <p>


                      ¡Absolutamente! Puedes hacer videos con o sin audio.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default pannei-width">
                  <div className="panel-heading" role="tab" id="headingFour">
                    <h4 className="panel-title">
                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour"
                        aria-expanded="false" aria-controls="collapseThree">
                        ¿Grabar Video es gratis?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body">
                      <p>
                      Si. Grabar Video ofrece un nivel gratuito para nuestro servicio que será suficiente para la 
mayoría de los usuarios. Entra en contacto con nosotros si estás buscando una oferta o 
asociación única.
                      </p>
                    </div>
                  </div>
                </div>
                

                
              </div>
            </div>
          </section>
          <section className="ready-rec-bg mt-40">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="capture-head">
                    <h2>Grabar Video</h2>
                    {/* <p>Sign Up with Pine Recorder </p> */}
                    <div className="ready-rec">
                    <Link to={'/register'}><i className="fa fa-circle" aria-hidden="true"></i> Grabar un video ahora</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer/>
         
        </>
    )
}

export default Home