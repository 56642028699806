import React from 'react';
import {Link} from 'react-router-dom';
import Nav from './Nav';
import Footer from './Footer';
import Slider from "react-slick";
import Faq from "./Faq";

function NextArrow(props) {
    const { onClick } = props;
    return (
      <span class="next_arrow" onClick={onClick}><i class="fa fa-angle-right" aria-hidden="true"></i></span> 
    );
  }
  
  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <span class="priv_arrow" onClick={onClick}><i class="fa fa-angle-left" aria-hidden="true"></i></span>
    );
  }


function ContactSales(){

    let sliderSettings = {
        prevArrow:<PrevArrow />,
         nextArrow:<NextArrow/>,
         slidesToShow: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };


    return(
        <>
       <Nav/>
          <section className="vid-main">
            <div className="container">
              <div className="row">
                <div className="col-md-6 pull-right">
                  <div className="video">
                    <img src="/newsite/assest/images/vid-screen.png"/>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text">
                    <h1><span>#1</span> screen & webcam
                      Recording software</h1>
                    <small>Record your screen and webcam. Online, free</small>
                    <Link to={'/recording'}><i className="fa fa-circle" aria-hidden="true"></i> Start Recording Now</Link>
                  </div>
                </div>

              </div>
            </div>
          </section>

        <section className="user-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center user-guid-head">
                    <h2>User Guide</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="box-bg box-num-pos">
                    <div className="box-num">
                      <span>1</span>
                    </div>
                    <strong>Start Recording</strong>
                    <p>Click"Start Recording"to activate this Online recorder Recording"to activate .</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="box-bg box-head-clr box-num-pos user-guid-mt">
                    <div className="box-num-cus">
                      <span>2</span>
                    </div>
                    <strong>Customize</strong>
                    <p>Customize the recording mode and region,then start recording.</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="box-bg box-num-pos user-mtb">
                    <div className="box-num">
                      <span>3</span>
                    </div>
                    <strong>Save Video</strong>
                    <p>Save the video to local disk or upload it to ReCloud,Youtube,etc.</p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="user-btn text-center mtb-50">
                    <a href=""> <i className="fa fa-play-circle" aria-hidden="true"></i> Watch Video Guide</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="screen-back">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center user-guid-head">
                    <h2>Screen Recording Tutorial</h2>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="tutorial-img">
                    <img className="img-w" src="/newsite/assest/images/recoding_tutorial_1.png" alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="capture-bg mt-88">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="capture-head">
                    <div className="drop-point">
                    </div>
                    <div className="drop-circle"></div>
                    <h2>Capture Your Screen With Ease </h2>
                    <p>Creating anything from webinars and product demos to gaming videos and teacher lesson plans, doesn’t have
                      to be difficult. Online video editor makes it easy and fun to screencast, edit and publish. Quickly go
                      from recording your screen or webcam, or both, to editing in a matter of seconds.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="traingle_shape">
            <div className="container">
              <div className="mtb-30">
                <div className="row">
                  <div className="col-md-12">
                    <div className="screen-rec">
                      <h2>How to get a video from this <span>online screen</span> recorder?</h2>
                    </div>
                  </div>
                </div>
                <div className="online-text online-pos">
                  <div className=" online-flex">
                    <div className="num-look mt-45 text-c">
                      <span>1</span>
                      <ul>
                        <li className="dot_1">
                          <img src="/newsite/assest/images/dot1.png" alt=""/>
                        </li>
                        <li className="dot_2">
                          <img src="/newsite/assest/images/dot2.png" alt=""/>
                        </li>
                        <li className="dot_3">
                          <img src="/newsite/assest/images/dot3.png" alt=""/>
                        </li>
                      </ul>
                    </div>
                    <div className="online-para online-screen mt-30">
                      <h2 className="text-c">A Simple Click On "Start Recording"</h2>
                      <p className="text-c">No need to install a plugin or extensions, just open this online recorder in Chrome,
                        Firefox, and
                        Opera.</p>
                    </div>
                  </div>
                  <div className=" online-flex">
                    <div className="num-look mt-60 text-c">
                      <span>2</span>
                      <ul>
                        <li className="dot_1">
                          <img src="/newsite/assest/images/dot1.png" alt=""/>
                        </li>
                        <li className="dot_2">
                          <img src="/newsite/assest/images/dot2.png" alt=""/>
                        </li>
                        <li className="dot_3">
                          <img src="/newsite/assest/images/dot3.png" alt=""/>
                        </li>
                      </ul>
                    </div>
                    <div className="online-para2 online-screen mt-35">
                      <h2 className="text-c">Choose the record model to start recording.</h2>
                      <p className="text-c">3 types of modes can be selected here: a full-screen recorder, a software window
                        recorder, and a browser tab recorder.</p>
                    </div>
                  </div>
                  <div className="online-flex">
                    <div className="num-look mt-50 text-c">
                      <span>3</span>
                    </div>
                    <div className="online-para3 online-screen mt-text">
                      <h2 className="text-c">Stop recording & save screen video to your computer</h2>
                      <p className="text-c">All screen videos could be saved in "WebM" format.Users can view them on Windows'
                        default player.</p>
                    </div>
                  </div>
                </div>
                
              
                
                <div className="bg-design"></div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-img">
                    <img className="img-w" src="/newsite/assest/images/screen_recording.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-head">
                    <h2>Screen record from anywhere.</h2>
                    <p>The screen-recording programs included in the bundle enable you to capture everything from desktop
                      activity and program use to the latest video games in Full HD or 4K.</p>
                    <a href="" className="btn-explore">Explore</a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="slider">
                <div className="row ">
                  
                  
                  <Slider {...sliderSettings}>
                    <div className="col-md-12 ">
                      <div className="testi-w">
                        <div className="test-img">
                          <div className="testimonial-img">
                            <img src="/newsite/assest/images/img-slider.png" alt=""/>
                          </div>
                          <div className="content testimonial">
                            <h2>All-in-One Video Suite For Your Video Content</h2>
                            <span className="under-line">Screen Recorder</span>
                            <p>Best-in-class features, value for money, and compatibility makes pinevideo recoder the best tool
                              for
                              making screen videos for your content</p>
                            <a href="" className="btn-explore">Explore</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 ">
                      <div className="testi-w">
                        <div className="test-img">
                          <div className="testimonial-img">
                            <img src="/newsite/assest/images/img-slider.png" alt=""/>
                          </div>
                          <div className="content testimonial">
                            <h2>All-in-One Video Suite For Your Video Content</h2>
                            <span className="under-line">Screen Recorder</span>
                            <p>Best-in-class features, value for money, and compatibility makes pinevideo recoder the best tool
                              for
                              making screen videos for your content</p>
                            <a href="" className="btn-explore">Explore</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Slider>
                  


                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-img screen-recorder2">
                    <img className="img-w" src="/newsite/assest/images/screen-recorder2.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="side-head">
                    <h2>Screen record from anywhere.</h2>
                    <p>The screen-recording programs included in the bundle enable you to capture everything from desktop
                      activity and program use to the latest video games in Full HD or 4K.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          
          <Faq/>    
          <Footer/>
        </>
    );
}
export default ContactSales;