import React,{useEffect,useState} from 'react';
import Nav from "./Nav";
import { Link,useParams,useHistory } from 'react-router-dom';

import swal from 'sweetalert';
import ApiUrl from "./utils/ApiUrl";
import Axios from "axios";
import { Button, Modal } from "react-bootstrap";
import VideoThumbnail from 'react-video-thumbnail';
import Footer from './Footer';

import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
  } from "react-share";


import {
  FacebookShareCount,
  HatenaShareCount,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
  } from "react-share";

function EditVideo(){
    const {videoid} = useParams();
    const [videos, setVideos] = useState(null);
    let sessionUserId = localStorage.getItem("userId");
    const history = useHistory();
    const [videoThumb, setvideoThumb] = useState('');
    const [recordingTitleValue, setRecordingTitle] = useState('');
    const [uploadedFile, setUploadedFile] = useState('');
    
    const [videoShareUrl,setVideoShareUrl] = useState('');
    const [videoUrl,setVideoUrl] = useState('');

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    
    useEffect(() => {
      
        getVideosDetail();
        async function getVideosDetail() {
           
           const requestOptions = {
               method: 'post',
               body: JSON.stringify({ "user":sessionUserId,"video":atob(videoid)}),
               headers: {
                   Accept: 'application/json',
                   // crossDomain : true,
                   'Content-Type': 'application/json'
                   
               } 
              
           }
           await fetch(ApiUrl+"api/getVideoDetails", requestOptions)
               .then(response => response.json())
               .then((data) => {
                 //   console.log(data);
                   if(data.status === 'success'){
                       let videodata = data.data;
                       setVideos(videodata);
                       if(videodata[0].video_thumb==null){
                        setvideoThumb(videodata[0].video_thumb);
                       }
                       else{
                        setvideoThumb(ApiUrl+`video_thumb/`+videodata[0].video_thumb);
                       }
                       setVideoUrl(ApiUrl+`video/`+videodata[0].video_url)
                       setRecordingTitle(videodata[0].video_title);
                       //console.log(videodata);
                    //    $('#example').DataTable();
                   }
                   else{
                       swal("Sorry! Please try again", "", "error");
                   }
                   
               });
   
         }
       }, []);
     
    const videoThumbUpload = (e) =>{
        e.preventDefault();
        var file  = e.target.files[0];
        var url = URL.createObjectURL(file)
        setUploadedFile(e.target.files[0]);
        setvideoThumb(url);
    }   

    const setTitle = (e) =>{
        e.preventDefault();
        setRecordingTitle(e.target.value);
    }

    function shareUrl(e){
        e.preventDefault();
        var url  = e.target.id;
        navigator.clipboard.writeText(url)
        swal("Successfully copied !", "", "success");
    }

    function shareUrlSocial(e){
        e.preventDefault();
        var url  = e.target.id;
        setVideoShareUrl(url);
        setShow(true);

    }
    function shareUrlThumb(e){
        e.preventDefault();
        var videourl  = e.target.id;
        var thumb  = e.target.attributes.getNamedItem('data-thumb').value;
        
        navigator.clipboard.writeText(
              '<a href="'+videourl+'"><p>Recorder | FreeScreen & Video Recording Software - Watch Video </p><img style="max-width:300px;" src="'+thumb+'"/></a>'
        )
        swal("Successfully copied !", "", "success");
    }
    
    const update = (e) =>{
        e.preventDefault(); 
        const formData = new FormData();
        formData.append('user', sessionUserId);
        formData.append('file', uploadedFile);
        formData.append('video', atob(videoid));
        formData.append('title', recordingTitleValue);
        
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
                
            } 
        }
        Axios.post(ApiUrl+'api/uploadVideoThumbnail', formData, config).then((response)=> {
            if(response.status == 200){
            swal("Successfully Updated", "", "success");
            history.push("/getvideos");
            }
        }).catch((err)=> {
            console.log(err)
        })

        
    }  


    return (
        <>
        <Nav/>
            {/* <div className="container text-center mt-5">
            <div className="row">
            {videos && (
                        <>
                        
                        {videos.map((video, index) => 
                        
                        (
                        
                        <div className="col-md-6">
                            <h3 className="">Recording Preview</h3>
                            <video width="500" height="350" controls poster={video.video_url != null ? ApiUrl+`video_thumb/`+video.video_thumb : ApiUrl+`video_thumb/no_thumb_found.jpg`}>
                                <source src={ApiUrl+`video/`+video.video_url} type="video/mp4"/>
                            </video>
                        </div>

                ))}
                        
                        </>
                      )}



                <div className="col-md-6 text-center">
                    <div className="">
                        <h4>Recording Thumbnail</h4>
                        {
                            videoThumb == null ? <VideoThumbnail
                            videoUrl= {videoUrl}
                            thumbnailHandler={(thumbnail) => thumbnail}
                            width={50}
                      /> : <a target="_blank" href={videoThumb} className="img_thumb">
                            <img src={videoThumb} alt="Forest" width={'150px'}/>
                           </a>   
                        }
                        
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="share_mr input-form">
                                    <label>Take New Thumbnail</label>
                                    <div className="if-file">
                                        <label htmlFor="file">
                                            <span>Browse...</span>No File Selected
                                        </label>
                                        <input id="file" type="file" onChange={videoThumbUpload}/>
                                    </div>
                                  </div>
                                <div className="share_mr input-form">
                                    <label>Recording Title</label>
                                    <input type="text" placeholder="Enter the title" className="share_input" onChange={setTitle} value={recordingTitleValue}/>
                                   
                                </div>
                                <div className="share_mr input-form">
                                    <a href="" className="btn-user" onClick={update} >Update</a>
                               </div>
                            </div>
                        </div>
                    
                        
                    </div>
                    <div className="margin_t">
                    {videos && (
                        <>
                        {videos.map((video, index) => 
                        
                        (
                        <>
                        <a href="" className="btn-user" data-toggle="modal" onClick={shareUrlSocial} id={window.location.origin+'/preview/'+btoa(video.id)+'/'+video.video_url} >Share</a>
                        <a href="" className="btn-user" onClick={shareUrl} id={window.location.origin+'/preview/'+btoa(video.id)+'/'+video.video_url}>Copy URL only</a>
                        <a href="" className="btn-user" onClick={shareUrlThumb} id={window.location.origin+'/preview/'+btoa(video.id)+'/'+video.video_url} data-thumb={ApiUrl+`video_thumb/`+video.video_thumb}>Copy URL & Thumbnail</a>
                       
                        </>
                        ))}
                        </>
                        
                      )}
                    </div>
                </div>
            </div>
        </div>  */}


        <section class="rec-main">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="banner-img share-head">
                        <h2>Recording Preview</h2>
                        {/* <img src="/newsite/assest/images/vid-screen.png" /> */}
                        {videos && (
                        <>
                        
                        {videos.map((video, index) => 
                        
                        (
                            <video width="500" height="350" controls poster={video.video_url != null ? ApiUrl+`video_thumb/`+video.video_thumb : ApiUrl+`video_thumb/no_thumb_found.jpg`}>
                                <source src={ApiUrl+`video/`+video.video_url} type="video/mp4"/>
                            </video>
                            
                        ))}
                        
                        </>
                      )}
                    </div>

                    <div class="share_rec">
                            <span>Share Recording</span>
                            <div class="copy-btn">
                               
                               {videos && (
                                    <>
                                    {videos.map((video, index) => 
                                    
                                    (
                                    <>
                                    <a href="" className="btn-user" data-toggle="modal" onClick={shareUrlSocial} id={window.location.origin+'/preview/'+btoa(video.id)+'/'+video.video_url} >Share</a>
                                    <a href="" className="btn-user" onClick={shareUrl} id={window.location.origin+'/preview/'+btoa(video.id)+'/'+video.video_url}>Copy URL only</a>
                                    <a href="" className="btn-user" onClick={shareUrlThumb} id={window.location.origin+'/preview/'+btoa(video.id)+'/'+video.video_url} data-thumb={ApiUrl+`video_thumb/`+video.video_thumb}>Copy URL & Thumbnail</a>
                                   
                                    
                                    
                                
                                    </>
                                    ))}
                                    </>
                                    
                                )}
                            </div>
                        </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="thumbnail-save">
                        <h3>Recording Thumbnail</h3>
                        <div class="thumb_img">
                            {/* <img src="/newsite/assest/images/thumb.png" alt="" /> */}
                            {
                            videoThumb == null ? <VideoThumbnail
                            videoUrl= {videoUrl}
                            thumbnailHandler={(thumbnail) => thumbnail}
                            width={50}
                      /> : <a target="_blank" href={videoThumb} className="img_thumb">
                            <img src={videoThumb} alt="Forest" width={'150px'}/>
                           </a>   
                        }

                            <div class="new_thm">
                                {/* <a href="">Take New Thumbnail</a> */}
                                <label htmlFor="file">
                                            <span>Take New Thumbnail</span>
                                        </label>
                                        <input id="file" type="file" onChange={videoThumbUpload}/>
                            </div>

                           

                        </div>
                        <div class="recording-title">
                            <span>Recording Title</span>
                            <div class="text-input">
                                <input type="text" placeholder="Enter The Title" className="share_input" onChange={setTitle} value={recordingTitleValue}/>
                                
                                <div class="copy-btn">
                                   <a href="" onClick={update} >Update</a>
                                </div>
                             </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

  {/*}  <section class="mtb-75">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="output">
                        <img src="/newsite/assest/images/setting1.png" alt="" />
                        <h3>Output Formats</h3>
                        <p>Export videos to MP4, WMV, AVI, FLV, MPEG, VOB, ASF and GIF</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="output scenario">
                        <img src="/newsite/assest/images/setting2.png" alt="" />
                        <h3>Scenarios</h3>
                        <p>Record all types of activities on your screen including video stream, self-demonstration,
                            live chat and more</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="mtb-75">
        <div class="container">
            <div>
                <div class="save_video">
                    <h2>Save and Share Video Easily</h2>
                    <p>When you're done with the screencast, you will be given the options to either save the video into
                        local drive, or upload it to cloud space at no cost. In the manner of storing your work on
                        cloud, you can easily share it to friends or colleagues by sending a link to your social network
                        or other channels.</p>
                </div>
                <div class="save_img">
                    <img src="/newsite/assest/images/share_save.png" alt="" />
                </div>
            </div>
        </div>

    </section>
    <section class="mtb-75">
        <div class="container">
            <div class="query">
                <h2>Frequently Asked Questions</h2>
                <p>Have Question? We have answers.</p>
            </div>
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default pannei-width">
                    <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne">
                                What is a screen recording?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel"
                        aria-labelledby="headingOne">
                        <div class="panel-body">
                            <p>A screen recording is a video that captures a full-screen view of your computer, laptop,
                                or mobile
                                device. With Vimeo Record, you can also capture your voice and/or webcam at the same
                                time. Screen
                                recordings are a great way to share the content on your device screen.
                            </p>

                        </div>
                    </div>
                </div>
                <div class="panel panel-default pannei-width">
                    <div class="panel-heading" role="tab" id="headingTwo">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                How do you record your screen?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                        <div class="panel-body">
                            <p>
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                                squid. 3 wolf
                                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt
                                laborum eiusmod.
                                Brunch 3 wolf moon tempor, sunt aliqua put a bird
                                on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                helvetica, craft
                                beer
                                labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice
                                lomo. Leggings
                                occaecat craft beer farm-to-table,
                                raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                sustainable VHS.

                            </p>
                        </div>
                    </div>
                </div>
                <div class="panel panel-default pannei-width">
                    <div class="panel-heading" role="tab" id="headingThree">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Can I edit my screen recordings?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseThree" class="panel-collapse collapse" role="tabpanel"
                        aria-labelledby="headingThree">
                        <div class="panel-body">
                            <p>


                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                                squid. 3 wolf
                                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt
                                laborum eiusmod.
                                Brunch 3 wolf moon tempor, sunt aliqua put a bird
                                on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                helvetica, craft
                                beer
                                labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice
                                lomo. Leggings
                                occaecat craft beer farm-to-table,
                                raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                sustainable VHS.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="panel panel-default pannei-width">
                    <div class="panel-heading" role="tab" id="headingFour">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                What are screen recorders?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseFour" class="panel-collapse collapse" role="tabpanel"
                        aria-labelledby="headingThree">
                        <div class="panel-body">
                            <p>
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                                squid. 3 wolf
                                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt
                                laborum eiusmod.
                                Brunch 3 wolf moon tempor, sunt aliqua put a bird
                                on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                helvetica, craft
                                beer
                                labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice
                                lomo. Leggings
                                occaecat craft beer farm-to-table,
                                raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                sustainable VHS.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="panel panel-default pannei-width">
                    <div class="panel-heading" role="tab" id="headingFive">
                        <h4 class="panel-title">
                            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
                                href="#collapseFive" aria-expanded="false" aria-controls="collapseFour">
                                How do I record internal audio?
                            </a>
                        </h4>
                    </div>
                    <div id="collapseFive" class="panel-collapse collapse" role="tabpanel"
                        aria-labelledby="headingThree">
                        <div class="panel-body">
                            <p>
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                                squid. 3 wolf
                                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt
                                laborum eiusmod.
                                Brunch 3 wolf moon tempor, sunt aliqua put a bird
                                on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                                helvetica, craft
                                beer
                                labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice
                                lomo. Leggings
                                occaecat craft beer farm-to-table,
                                raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                sustainable VHS.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="ready-rec-bg mt-40">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="capture-head">
                        <h2>Ready To Record?</h2>
                        <p>Sign Up with Pine Recorder to get start now </p>
                        <div class="ready-rec">
                            <a href="#"><i class="fa fa-circle" aria-hidden="true"></i> Start Recording Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>*/}

    <section className="ready-rec-bg mt-40">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="capture-head">
                  <h2>Grabar Video</h2>
                    {/* <p>Sign Up with Pine Recorder </p> */}
                    <div className="ready-rec">
                    <Link to={'/register'}><i className="fa fa-circle" aria-hidden="true"></i> Grabar un video ahora</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

    <Footer/>
    

        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <FacebookShareButton
            url={videoShareUrl}
            className=""
         >
        <FacebookIcon size={32} round />
        </FacebookShareButton>
        
        <TwitterShareButton url={videoShareUrl}>
           <TwitterIcon size={32} round />
        </TwitterShareButton>
           
         <TelegramShareButton url={videoShareUrl}>
             <TelegramIcon size={32} round />
         </TelegramShareButton>
          
          <WhatsappShareButton url={videoShareUrl}>
             <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          
          <LinkedinShareButton url={videoShareUrl}>
               <LinkedinIcon size={32} round/>

          </LinkedinShareButton>
          
         

          <EmailShareButton url={videoShareUrl}>
             <EmailIcon size={32} round/> 
          </EmailShareButton>


        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      </Modal>

        </>
    )
}
export default EditVideo;