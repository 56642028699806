import React,{ useState } from 'react';
import swal from 'sweetalert';
import {useHistory,Link,NavLink,Redirect} from 'react-router-dom';

import Nav from './Nav';
import Footer from './Footer';

import ApiUrl from "./utils/ApiUrl";
var algorithm = 'aes256'; // or any other algorithm supported by OpenSSL
var key = 'password';
var crypto = require('crypto');

const Contactus = () => {
    
    const [fNameValue, setterFName ] = useState('');
    const [lNameValue, setterlName ] = useState('');
    const [phoneValue, setterPhone ] = useState('');
    const [cityValue, setterCity ] = useState('');
    const [messageValue, settermessage ] = useState('');
    const history = useHistory();
    
    
     const setFirstName  = (event) =>{
        setterFName(event.target.value);   
    }
    const setLastName  = (event) =>{
        setterlName(event.target.value);   
    }
    const setPhone  = (event) =>{
        setterPhone(event.target.value);   
    }
    const setCity  = (event) =>{
        setterCity(event.target.value);   
    }
    const setmessage  = (event) =>{
        settermessage(event.target.value);   
    }
  
    const submitRegister = (event) =>{
        event.preventDefault();
         
        if(fNameValue !=""){
        
            if (lNameValue != ""){
                 
                if(phoneValue !=""){
    
                    if(cityValue !=""){
                         
                        if(messageValue !=""){
                                
                            // if (passwordValue == cnfrmPasswordValue){
                                
                                // var cipher = crypto.createCipher(algorithm, key);  
                                // var encrypted = cipher.update(passwordValue, 'utf8', 'hex') + cipher.final('hex');

                                const requestOptions = {
                                    method: 'post',
                                    body: JSON.stringify({ "fname":fNameValue,"lname":lNameValue,"phone":phoneValue,"city":cityValue,"message":messageValue}),
                                    headers: {
                                        Accept: 'application/json',
                                        // crossDomain : true,
                                        'Content-Type': 'application/json'
                                        
                                    } 
                                   
                                }
                                fetch(ApiUrl+"api/contactUs", requestOptions)
                                    .then(response => response.json())
                                    .then((data) => {
                                       if(data.status == 200){
                                            swal("Thanks! we will contact you, Very soon!", "", "success");
                                            history.push("/");
                                        }
                                        else{
                                            swal(data.msg, "", "error");
                                        }
                                        
                                    });
    
                            // }else{
                            //     swal("Please Enter Password And Confirm Password Same", "", "error");
                            // }
                        }else{
                            swal("Please Enter Message value ", "", "error");
                        }
                    }else{
                        swal("Please Enter City value ", "", "error");
                    }
    
                }else{
                    swal("Please Enter Phone Number", "", "error");   
                }
    
                 
            }else{
                swal("Please Enter Last Name", "", "error");   
            }
    
        }else{
            swal("Please Enter First Name", "", "error"); 
        }
      }



    return(
        <>
        <Nav/>
        <section className="contact-img">
        <div className="container">
            <div className="contact-bg">

           
            <div className="row">
                <div className="col-md-6">
                    
                <div className="col-lg-6 col-md-12 bg-color-15 pad-0 none-992 bg-img clip-home"></div>
                </div>
                <div className="col-md-6">
                    <div className="">
                        <div className="mt-100 contact-cnt">
                            <h3>Contact Us</h3>
                            <form onSubmit={ submitRegister }>
                                <div className="col-md-6">
                                    <div className="">
                                        <input type="text" name="f_name" className="form-control select-opt" placeholder="First Name"
                                            aria-label="Email Address" onChange={setFirstName} value={fNameValue}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="">
                                        <input type="text" name="lname" className="form-control select-opt" placeholder="Last Name"
                                            aria-label="Email Address" onChange={setLastName} value={lNameValue}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="">
                                        <input type="num" name="phone" className="form-control select-opt" placeholder="Phone Number"
                                            aria-label="Email Address" onChange={setPhone}  value={phoneValue}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="">
                                    <input type="text" name="city" className="form-control select-opt" placeholder="City"
                                            aria-label="Email Address" onChange={setCity} value={cityValue}/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div>
                                        <textarea className="text-area" placeholder="Enter Message" rows="20"
                                              cols="40"
                                             autoComplete="off" 
                                             onChange={setmessage} value={messageValue} />  
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="send-btn">
                                        <button type="submit" className="btn-md btn-theme float-start">Send Message</button>
                                    </div>
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
    <Footer/>
        </>
    );

}

export default Contactus;