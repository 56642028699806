import React,{ useState } from 'react';
import swal from 'sweetalert';
// import { ReactSession } from 'react-client-session';
import { useHistory,Link,Redirect  } from "react-router-dom";
// import PropTypes from "prop-types";
import { GoogleLogin,GoogleLogout } from 'react-google-login';

// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { refreshTokenSetup } from './utils/refreshTokens';
import { clearLocalStorage } from './utils/clearLocalStorage';
import ApiUrl from "./utils/ApiUrl";
import ClientId from "./utils/ClientId";
import Nav from './Nav';
import FacebookAppID from "./utils/FacebookAppID";

var crypto = require('crypto');

var algorithm = 'aes256'; // or any other algorithm supported by OpenSSL
var key = 'password';


const clientId = ClientId;

const Login = () => {

    
    const history = useHistory();


    
    const [emailValue, setterEmail ] = useState('');
    const [passwordValue, setterPassword ] = useState('');
   
    /* change the value of of text field and set the value in new variable */
    const setEmail  = (event) =>{
        setterEmail(event.target.value);   
    }
    const setPassword  = (event) =>{
        setterPassword(event.target.value);   
    }

    var sessionUser = localStorage.getItem("userId");

        const submitLogin = (event) =>{
            event.preventDefault();

            var cipher = crypto.createCipher(algorithm, key);  
            var encrypted = cipher.update(passwordValue, 'utf8', 'hex') + cipher.final('hex');
            
            const requestOptions = {
                method: 'post',
                body: JSON.stringify({"email":emailValue,"password":encrypted}),
                headers: {
                    Accept: 'application/json',
                    // crossDomain : true,
                    'Content-Type': 'application/json'
                    
                } 
               
            }
            fetch(ApiUrl+"api/LoginUser", requestOptions)
                .then(response => response.json())
                .then((data) => {
                    if(data.status == 200){
                        let userID = data.data[0].id;
                        let userEmail = data.data[0].email;
                        let userName = data.data[0].first_name+' '+data.data[0].last_name;   
                        localStorage.setItem("userId",userID)
                        localStorage.setItem("userEmail",userEmail)
                        localStorage.setItem("userName",userName)
                        swal("Successfully Login", "", "success");
                        history.push("/recording");
                    }
                    else{
                        swal(data.msg, "", "error");
                    }
                    
                });
    
        }

        const onSuccessGoogle = (res) => {
            var name  = res.profileObj.name
            var email = res.profileObj.email
            var firstname = res.profileObj.givenName
            var lastname = res.profileObj.familyName
            
           
            const socialLoginHeader = {
                method: 'post',
                body: JSON.stringify({"email":email,"first_name":firstname,"last_name":lastname}),
                headers: {
                    Accept: 'application/json',
                    //crossDomain : true,
                    'Content-Type': 'application/json'
                    
                } 
               
            }
            fetch(ApiUrl+"api/SocialLogin", socialLoginHeader)
                .then(response => response.json())
                .then((data) => {
                    if(data.status === 'success'){
                        let userID = data.data[0].id;
                        let userEmail = data.data[0].email;
                        let userName = data.data[0].first_name+' '+data.data[0].last_name;   
                        localStorage.setItem("userId",userID)
                        localStorage.setItem("userEmail",userEmail)
                        localStorage.setItem("userName",userName)
                       
                        swal("Successfully Login", "", "success");
                        history.push("/recording");

                    }
                    else{
                        swal("Sorry! Please try again", "", "error");
                    }
                    
                });
             refreshTokenSetup(res);
           

          };
        
          const onFailureGoogle = (res) => {
            // console.log('Login failed: res:', res);
            swal("Failed to login. 😢", "", "error");
        };

        const responseFacebook = (response) => {
            var email = response.email;
            var firstname = response.name;
            
            if(email){
                const socialLoginHeader = {
                    method: 'post',
                    body: JSON.stringify({"email":email,"first_name":firstname,"last_name":''}),
                    headers: {
                        Accept: 'application/json',
                        //crossDomain : true,
                        'Content-Type': 'application/json'
                        
                    } 
                   
                }
                fetch(ApiUrl+"api/SocialLogin", socialLoginHeader)
                    .then(response => response.json())
                    .then((data) => {
                        if(data.status === 'success'){
                            let userID = data.data[0].id;
                            let userEmail = data.data[0].email;
                            let userName = data.data[0].first_name+' '+data.data[0].last_name;   
                            localStorage.setItem("userId",userID)
                            localStorage.setItem("userEmail",userEmail)
                            localStorage.setItem("userName",userName)
                            
                            swal("Successfully Login", "", "success");
                            history.push("/recording");
                           
                        }
                        else{
                            swal("Sorry! Please try again", "", "error");
                        }
                        
                    });
            }
           
        }
        // const onSuccessGoogleLogout = () => {
        //     console.log('Logout made successfully');
        //     alert('Logout made successfully ✌');
        // };
        

        return(
            <>
            {
                sessionUser ? <Redirect to = "/screenrecorder"></Redirect> : null 
            }
          <section className="login-3">
        <div className="container-fluid">
            <div className="row login-box">
                <div className="col-lg-6 col-md-12 align-self-center pad-0 form-section">
                    <div className="form-inner">
                        <Link to="/" className="logo">
                            <img src="/newsite/assest/images/logo-white.png" alt="logo"/>
                        </Link>
                        <h3>Sign Into Your Account</h3>
                        <form onSubmit={ submitLogin }>
                            <div className="form-group form-box">
                                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                <input type="email" name="email" className="form-control" placeholder="Email Address" aria-label="Email Address" onChange={setEmail} value={emailValue}/>
                            </div>
                            <div className="form-group form-box clearfix">
                                <i className="fa fa-key" aria-hidden="true"></i>
                                <input type="password" name="password" className="form-control" autoComplete="off" placeholder="Password" aria-label="Password" onChange={setPassword} value={passwordValue}/>
                            </div>
                            <div className="form-group mb-0 clearfix">
                                <button type="submit" className="btn-md btn-theme float-start">Login</button>
                                <Link to="/forgerPassword" className="forgot-password">Forgot Password</Link>
                                
                            </div>
                        </form>
                            <div className="extra-login clearfix">
                                <span>Or Login With</span>
                            </div>
                            <div className="clearfix"></div>
                            <ul className="social-list">
                                <li>
                                <FacebookLogin
                                    appId={FacebookAppID}
                                    render={renderProps => (
                                        <a href="#" className="facebook-color" onClick={renderProps.onClick}><i className="fa fa-facebook facebook-i"></i><span>Facebook</span></a>
                                    )}
                                    cssclass="icon_fb"
                                    icon="fa fa-facebook"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={responseFacebook}
                                    
                                />
                                
                                </li>
                               
                               
                                {/* <li><a href="#" className="twitter-color"><i className="fa fa-twitter twitter-i"></i><span>Twitter</span></a></li> */}
                                <li>
                                <GoogleLogin
                                    clientId={clientId}
                                    render={renderProps => (
                                        <a href="#" className="google-color" onClick={renderProps.onClick}><i className="fa fa-google google-i"></i><span>Google</span></a>
                                    )}
                                    onSuccess={onSuccessGoogle}
                                    cookiePolicy={'single_host_origin'}
                                    style={{ marginTop: '100px' }}
                                    isSignedIn={true}
                                 />
                                </li>
                            </ul>
                        
                        <div className="clearfix"></div>
                        <p>Don't have an account? <Link to="/register" className="thembo"> Register here</Link></p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 bg-color-15 pad-0 none-992 bg-img clip-home"></div>
            </div>
        </div>
    </section>


            
             
            </>
        )
    // }
}

export default Login;