import React,{ useState,useEffect } from 'react';
import Nav from "./Nav";
import { useHistory,Link,Redirect  } from "react-router-dom";
import ApiUrl from "./utils/ApiUrl";
import swal from 'sweetalert';
import e from 'cors';
import Footer from './Footer';

var crypto = require('crypto');

var algorithm = 'aes256'; // or any other algorithm supported by OpenSSL
var key = 'password';


function Profile(){
    
    const history = useHistory();
    let sessionUserId = localStorage.getItem("userId");
    const [emailValue, setterEmail ] = useState('');
    
    const [profile, setProfile] = useState(null);
    const [isPasswordChange, setPasswordChange] = useState(false);
    
    /* change the value of of text field and set the value in new variable */
    const [currentPassword, setterCurrentPassword ] = useState('');

    const [passwordValue, setterPassword ] = useState('');
    const [NewpasswordValue, setterNewPassword ] = useState('');
    const [ConfirmNewpasswordValue, setterConfirmNewPassword ] = useState('');

    const setEmail  = (event) =>{
        setterEmail(event.target.value);   
    }
    const setPassword  = (event) =>{
        setterPassword(event.target.value);   
    }
    const setNewPassword = (event) =>{
        setterNewPassword(event.target.value);   
    }
    const setConfirmNewPassword = (event) =>{
        setterConfirmNewPassword(event.target.value);   
    }
    

    useEffect(() => {
      
        getVideos();
        async function getVideos() {
           
           const requestOptions = {
               method: 'post',
               body: JSON.stringify({ "user":sessionUserId}),
               headers: {
                   Accept: 'application/json',
                   // crossDomain : true,
                   'Content-Type': 'application/json'
                   
               } 
              
           }
           await fetch(ApiUrl+"api/getUserProfile", requestOptions)
               .then(response => response.json())
               .then((data) => {
                 //   console.log(data);
                   if(data.status == 200){
                       let ProfileData = data.data[0];
                       var userEmail = ProfileData.email;
                       var userPassword = ProfileData.password;
                          

                       var decipher = crypto.createDecipher(algorithm, key);
                       var decryptedPassword = '';
                       if(userPassword != null){
                         decryptedPassword = decipher.update(userPassword, 'hex', 'utf8') + decipher.final('utf8');
                       }
                      
                       
                       setterEmail(userEmail);
                       setterCurrentPassword(decryptedPassword);
                   }
                   else{
                       swal("Sorry! Please try again", "", "error");
                   }
                   
               });
   
         }
       }, []);
   

    const ProfileUpdate = (event) =>{
        event.preventDefault();
        var password  = currentPassword;
        var validate = true;
        
        if(isPasswordChange){
            if(passwordValue==currentPassword){
                validate = true;
                if(NewpasswordValue==ConfirmNewpasswordValue){
                    validate = true;
                    var password  = NewpasswordValue;

                }
                else{
                    validate = false; 
                    swal("New Password and Confirm Password Not Match", "", "error");
                }
            }
            else{
                validate = false;
                swal("Current Password Is Invalid", "", "error");
            }
        }
        if(validate==true){
            var cipher = crypto.createCipher(algorithm, key);  
            var encrypted = cipher.update(password, 'utf8', 'hex') + cipher.final('hex');
            const requestOptions = {
                method: 'post',
                body: JSON.stringify({"email":emailValue,"password":encrypted,"user":sessionUserId}),
                headers: {
                    Accept: 'application/json',
                    // crossDomain : true,
                    'Content-Type': 'application/json'
                    
                } 
               
            }
            fetch(ApiUrl+"api/ProfileUpdate", requestOptions)
                .then(response => response.json())
                .then((data) => {
                    if(data.status == 200){
                        swal("Profile Successfully Update", "", "success");
                        setPasswordChange(false);
                        history.push("/profile");
                    }
                    else{
                        swal(data.msg, "", "error");
                    }
                    
            });
        }
        

    }

    function setShowPassword(){
       setPasswordChange(true);
    }

    function changePassword(){
        if(isPasswordChange){
            return <>
                        {/* <div className="col-md-12 ">
                            <div className="input-form">
                                <label>Current Password</label>
                                <input type="password" className="login_page login_mr text-center" placeholder="Current Password" onChange={setPassword} value={passwordValue}  name="psw" id="" required/>
                            </div>
                        </div>

                        <div className="col-md-12 ">
                            <div className="input-form">
                                <label>New Password</label>
                                <input type="password" className="login_page login_mr text-center" placeholder="New Password" onChange={setNewPassword} value={NewpasswordValue} name="NewPsw" id="" required/>
                            </div>
                        </div>

                        <div className="col-md-12 ">
                            <div className="input-form">
                                <label>Confirm Password</label>
                                <input type="password" className="login_page login_mr text-center" placeholder="Confirm New Password" onChange={setConfirmNewPassword}  value={ConfirmNewpasswordValue} name="ConfirmNewPsw" id="" required/>
                            </div>
                        </div> */}


                        <div className="un">
                            <i className="fa fa-lock" aria-hidden="true"></i>
                            <input type="password" className="login_page login_mr text-center" placeholder="Currrent Password*" onChange={setPassword} value={passwordValue}  name="psw" id="" required/>
                        </div>
                        <div className="orp">
                            <i className="fa fa-lock" aria-hidden="true"></i>
                           
                            <input type="password" className="login_page login_mr text-center" placeholder="New Password *" onChange={setNewPassword} value={NewpasswordValue} name="NewPsw" id="" required/>
                        </div>
                        <div className="confp">
                            <i className="fa fa-lock" aria-hidden="true"></i>
                            <input type="password" className="login_page login_mr text-center" placeholder="Confirm Password *" onChange={setConfirmNewPassword}  value={ConfirmNewpasswordValue} name="ConfirmNewPsw" id="" required/>
                        </div>
                </>
        }
        else{
            return null;
        }
        
    }
    
    return(
        <>
       <Nav/>

       {/* <div className="container bg-color">
            <div className=" text-center">
                <div className="row">
                    <div className="col-md-12">
                        <p className="register">Profile Details</p>

                    </div>

                </div>

            </div>
            <form onSubmit={ ProfileUpdate }>
            <div className="">
                <div className="row">
                    <div className="col-md-12">
                        <div className="input-form">
                        <label>Username/Email</label>
                        <input type="Email" className={`login_page text-center`} placeholder="Enter Email" onChange={setEmail} value={emailValue} name="email" id="" required/>
                        </div>
                    </div>

                    {changePassword()}
                    
                    {
                        currentPassword ?  <>
                        <div className="col-md-7 ">
                        <div className="input-form">
                        <input type="button" value="Change Password" className="" onClick={setShowPassword}/>
                        </div>
                    </div>
                        </> : null
                    }
                    

                    <div className="col-md-5">
                        <div className="input-form">
                        <input type="submit" value="Update" className=""/>
                        </div>
                    </div>

                </div>

            </div>
            </form>
         </div> */}


         <div className="profile-padd">
         
            <form className="main" onSubmit={ ProfileUpdate }>
                <h1>Profile</h1>
                <div className="em">
                    <i className="fa fa-user" aria-hidden="true"></i>
                    <input type="Email" className={`login_page text-center`} placeholder="E-mail *" onChange={setEmail} value={emailValue} name="email" id="" required/>
                </div>
                {/* <div className="un">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    <input type="password" placeholder="Currrent Password*" required />
                </div>
                <div className="orp">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    <input type="password" placeholder="New Password *" required />
                </div>
                <div className="confp">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    <input type="password" placeholder="Confirm Password *" required />
                </div> */}
                {changePassword()}
                <div className="button-update">
                    
                    {
                        currentPassword ?  <>
                         <button onClick={setShowPassword}>Change Password </button>
                        </> : null
                    }

                    <button type="submit" className="share-ml">Update</button>

                </div>
            </form>
        </div>
        <Footer/>
        </>
    );
}
export default Profile;