import React,{useState} from "react";
import Nav from "./Nav";
import Webcam from "react-webcam";
import useScreenRecorder from "use-screen-recorder";
import Axios from "axios";
import swal from 'sweetalert';
import { Link,useHistory } from "react-router-dom";
import ApiUrl from "./utils/ApiUrl";
import $ from 'jquery';
import Footer from "./Footer";
import Faq from "./Faq";

const Recording = () => {
   
    const webcamRef = React.useRef(null);
    const mediaRecorderRef = React.useRef(null);
    const [capturing, setCapturing] = React.useState(false);
    const [recordedChunks, setRecordedChunks] = React.useState([]);
    const history = useHistory();
    //const videoRef = React.useRef(null);

    const [videoRef, setVideoRef] = React.useState(null);

    const [thumb,setThumb] = React.useState(null);
    

    const handleStartCaptureClick = React.useCallback(() => {
        setCapturing(true);
      
        // mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        //   mimeType: "video/mp4"
        // });

        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream);

        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable
        );
        mediaRecorderRef.current.start();
      }, [webcamRef, setCapturing, mediaRecorderRef]);
    
      const handleDataAvailable = React.useCallback(
        ({ data }) => {
          if (data.size > 0) {
            setRecordedChunks((prev) => prev.concat(data));
          }
        },
        [setRecordedChunks]
      );


      const startCamRecording = () => {
           if(capturing){
             return 'start-rec';
           }
           else{
             return 'false';
           }
      }
    
      const handleStopCaptureClick = React.useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
       
      }, [mediaRecorderRef, webcamRef, setCapturing]);

      const uploadVideocam = (e) =>{
        e.preventDefault();
        let sessionUserId = localStorage.getItem("userId");
        var fileName = Date.now() + '-' +'web-cam-recorder.mp4';
        const blob = new Blob(recordedChunks, {
          type: "video/mp4"
        });
        var wavefilefromblob = new File([blob],fileName,{mimeType: "video/mp4"});
        const formData = new FormData();
    
        formData.append('user', sessionUserId);
        formData.append('filename', fileName);
        formData.append('file', wavefilefromblob);
        
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
                
            } 
        }
        $(".loadingbox").show();
          Axios.post(ApiUrl+'api/uploadVideo', formData, config).then((response)=> {
            if(response.status == 200){
              $(".loadingbox").hide();
              swal("Successfully saved", "", "success");
              let videodata = response.data.data;
              var videoId = videodata[0].id;
             
              // history.push("/editvideo/"+videoId);

              history.push("/editvideo/"+btoa(videoId));
              
            }
            else{
              swal("Please Try again.", "", "error");
            }
          }).catch((err)=> {
            console.log(err)
          })
      }

      const {
        startRecording,
        pauseRecording,
        blobUrl,
        blob,
        resetRecording,
        resumeRecording,
        status,
        stopRecording,
      } = useScreenRecorder({ audio: true ,
                              video:true});
     
    // if(status==="recording"){
    //   setStartScreenRecording('rec-relative');
    // }

    const [recordingType,setRecordingType] = useState('');
    const recording = (e) =>{
        resetRecording();
                //  videoRef.current.load();
        setVideoRef(null);
        setRecordingType(e.target.id)  
        
    }
                    

    function uploadVideoScreen(e) {
        e.preventDefault();
        let sessionUserId = localStorage.getItem("userId");
        var fileName = Date.now() + '-' +'screenRecord.mp4';
        var wavefilefromblob = new File([blob],fileName,{mimeType: "video/mp4"});
        const formData = new FormData();
    
        formData.append('user', sessionUserId);
        formData.append('filename', fileName);
        formData.append('file', wavefilefromblob);
        
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
                
            } 
        }
        $(".loadingbox").show();
        Axios.post(ApiUrl+'api/uploadVideo', formData, config).then((response)=> {
            // console.log("Video Uploaded Correctly")
            //console.log("status"+response.status);
            if(response.status == 200){
              $(".loadingbox").hide();
                // swal("Successfully saved", "", "success");
                // history.push("/getvideos");
                swal("Successfully saved", "", "success");
                let videodata = response.data.data;
                var videoId = videodata[0].id;

                //history.push("/editvideo/"+videoId);
                history.push("/editvideo/"+btoa(videoId));
            }
            else{
              swal("Please Try again.", "", "error");
            }
            }).catch((err)=> {
            console.log(err)
            })
    }
                              
                              


     const renderStartButton = () =>{
       
        if(recordingType == 'cam'){
            if(capturing){
                return <> 
                 {/* <button onClick={handleStopCaptureClick} className="btn-user">Stop Recording</button>
                 <div className={startCamRecording()}></div>    */}

                 <a href="#" onClick={handleStopCaptureClick}>Stop Recording
                                          Now</a>
                       </>
            }
            else if(!capturing){
                // <button  className="btn-user">Start Recording</button> 
                return  <a href="#" onClick={handleStartCaptureClick}><i className="fa fa-circle" aria-hidden="true"></i> Start Recording
                                          Now</a>
                        
            }
        }
        else if (recordingType == 'screen'){
            if(status === "idle" || status === "error"){
                //  <button onClick={() => {
                //   startRecording();
                // }}  className="btn-user">Start recording</button>
                
                return <a href="#" onClick={() => {
                  startRecording();
                }}><i className="fa fa-circle" aria-hidden="true"></i> Start recording</a>
            }
            else if (status === "recording" || status === "paused"){
                return  <>
                {/* <button onClick={stopRecording} 
                onClick={() => {
                  stopRecording();
                }}
                className="btn-user">Stop recording</button>  */}
                
                <a href="#" onClick={stopRecording} 
                onClick={() => {
                  stopRecording();
                }}>Stop recording</a>
               
                {/* <button
                onClick={() =>
                  status === "paused" ? resumeRecording()  : pauseRecording()
                }
                className="btn-user">
                {status === "paused" ? "Resume recording" : "Pause recording"}
              </button> */}
              <a href="#" onClick={() =>
                  status === "paused" ? resumeRecording()  : pauseRecording()
                }>{status === "paused" ? "Resume recording" : "Pause recording"}</a>

              {/* <div className={startScreenRecording()}></div> */}
              
              </>
            }
            else if (status === "stopped"){
               return <>
               {/* <button
                 onClick={() => {
                   resetRecording()
                  //  videoRef.current.load()
                  setVideoRef(null);
                   
                 }}
                 className="btn-user">
                 Retry Recording
               </button> */}

               <a href="#" onClick={() => {
                   resetRecording()
                  //  videoRef.current.load()
                  setVideoRef(null);
                   
                 }}>Retry Recording</a>
                
               
               {/* <button
                 onClick={uploadVideoScreen}
                 className="btn-user">
                 Save & Edit Recording
               </button> */}

               <a href="#" className="edit-btn" onClick={uploadVideoScreen}>Save & Edit Recording</a>
               </> 
            }



        }
        else if (recordingType == 'both'){
          if(status === "idle" || status === "error"){
              // return <button onClick={startRecording} className="btn-user">Start recording</button>
              return <a href="#" onClick={startRecording}><i className="fa fa-circle" aria-hidden="true"></i> Start recording</a>
          }
          else if (status === "recording" || status === "paused"){
              return  <>
              {/* <button onClick={stopRecording} className="btn-user">Stop recording</button>  */}
              
              <a href="#" onClick={stopRecording}> Stop recording</a>

              {/* <button
              onClick={() =>
                status === "paused" ? resumeRecording() : pauseRecording()
              }
              className="btn-user">
              {status === "paused" ? "Resume recording" : "Pause recording"}
            </button> */}

            <a href="#" onClick={() =>
                status === "paused" ? resumeRecording() : pauseRecording()
              }>{status === "paused" ? "Resume recording" : "Pause recording"}</a>
            {/* <div className={startScreenRecording()}></div> */}
            </>
          }
          else if (status === "stopped"){
             return <>
             {/* <button
               onClick={() => {
                 resetRecording();
                //  videoRef.current.load();
                setVideoRef(null);
                 
               }}
               className="btn-user">
               Retry Recording
             </button> */}

             <a href="#" onClick={() => {
                 resetRecording();
                //  videoRef.current.load();
                setVideoRef(null);
                 
               }}>Retry Recording</a>
 
             {/* <button
               onClick={uploadVideoScreen}
               className="btn-user">
               Save & Edit Recording
             </button> */}
            
             
             <a href="#" className="edit-btn" onClick={uploadVideoScreen}>Save & Edit Recording</a>

             </> 
          }



      }
      }
      
      const renderUploadButton = () =>{
        if(recordingType == 'cam'){
            if(recordedChunks.length>0){
               return <>
                      {/* <button onClick={uploadVideocam} className="btn-user">Save & Edit Recording</button>  */}
                      <a href="#" className="edit-btn edit-ml" onClick={uploadVideocam}>Save & Edit Recording
                                          Now</a>
                      </>   
            }
            else{
                return null;
            }
          }
      }

      const videoRender = () =>{
        if(recordingType == 'cam'){
          return <>
          {/* <h3 className="mr-head">Webcam Preview</h3> */}
         
          <Webcam audio={true} ref={webcamRef} screenshotFormat="image/jpeg" width={'100%'} height={'auto'}/>
          
          </>
        }
        else if(recordingType == 'screen'){
          return <> 
          {/* <h3 className="mr-head">Screen Preview</h3> 
           <video
             width={'100%'}
             ref={videoRef}
             src={blobUrl}
             poster={"/img/screen-recorder-logo.jpg"}
             controls
             autoPlay
           /> */}
           <img src="/newsite/assest/images/vid-screen.png" />
           </>
        }
         else if(recordingType == 'both') {
            return <>
            {/* <h3 className="mr-head">Webcam Preview</h3> */}
            {/* <div className={startCamRecording()}> */}
            <Webcam audio={true} ref={webcamRef}  screenshotFormat="image/jpeg" width={'100%'} height={'auto'} id="both-webcam"/>
            {/* </div> */}
            </>
          }
          else {
            return <> 
            {/* <h3 className="mr-head">Screen & Cam Recorder </h3> 
           <video
             width={'100%'}
             poster={"/img/screen-recorder-logo.jpg"}
             controls
             autoPlay
           /> */}
            {/* <img src="/newsite/assest/images/vid-screen.png" /> */}
            <Webcam audio={true} ref={webcamRef} screenshotFormat="image/jpeg" width={'100%'} height={'auto'}/>
           </>
          }
      }

    //  const startScreenRecording = () =>{
    //    if(status==="recording"){
    //       return 'rec-relative';
    //    }
    //    else{
    //      return "false";
    //    }
    //  } 

    return (
         <>
        
         <Nav/>
         {/*
         <div className="loadingbox">
          <div id="overlayer"></div>
          <span className="loader">
            <span className="loader-inner"></span>
          </span>
          </div>
         <div className="container text-center mt-5">
            <div className="row">
                <div className="col-md-6">
                     {videoRender()}
                </div>
                
                <div className=" col-md-6 text-center">
                    <h3>Select Recording Type </h3>
                    <div className="parent icon_mr">
                        <div className="child icon-bor icon_clr" id="cam" onClick={recording}>
                          <div id="cam" onClick={recording}>
                            <i className="fa fa-camera" id="cam" onClick={recording}></i>
                            <p className="rec_school" id="cam" onClick={recording}>Cam only</p>
                          </div>
                        </div>
                        <div className="child icon-bor icon-mr icon_clr" id="screen" onClick={recording} >
                          <div id="screen" onClick={recording}>
                            <i className="fa fa-desktop" id="screen" onClick={recording} ></i>
                            <p className="rec_school" id="screen" onClick={recording} >Screen only</p>
                          </div>
                        </div>
                        <div className="child icon-bor1 icon-mr icon_clr" id="both" onClick={recording}>
                          <div id="both" onClick={recording}>
                            <i className="fa fa-camera" id="both" onClick={recording}></i>
                            <i className="fa fa-desktop" id="both" onClick={recording}></i>
                            <p className="rec_school" id="both" onClick={recording} >Both</p>
                          </div>
                        </div>
                    </div>
                    <div className="start_record ">
                            {renderStartButton()}
                            {renderUploadButton()}
                     </div>

                     

                    </div>


                </div>
           </div>
           */
           }

           <section className="rec-main">
              <div className="container">
                  <div className="row">
                      <div className="col-md-6">
                          <div className="banner-img">
                              {videoRender()}
                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="row">
                              <div className="col-lg-12">
                                  <div className="text">
                                      <h1>Select Recording Type</h1>
                                  </div>
                              </div>
                              <div>
                                  <div className="col-lg-4 col-md-4 col-sm-4" id="cam" onClick={recording}>
                                      <div className="cam_only" id="cam" onClick={recording}>
                                          <img src="/newsite/assest/images/cam_only.png" alt="" id="cam" onClick={recording} />
                                          <span id="cam" onClick={recording}>Cam Only</span>
                                      </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4 col-sm-4" id="screen" onClick={recording}>
                                      <div className="screen_only" id="screen" onClick={recording}>
                                          <img src="/newsite/assest/images/screen_only.png" alt="" id="screen" onClick={recording} />
                                          <span id="screen" onClick={recording}>Screen Only</span>
                                      </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4 col-sm-4" id="both" onClick={recording}>
                                      <div className="both" id="both" onClick={recording}>
                                          <img src="/newsite/assest/images/both.png" alt="" id="both" onClick={recording}/>
                                          <span id="both" onClick={recording}>Both</span>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                  <div className="rec_btn mt-40">
                                      {/* <a href="#"><i className="fa fa-circle" aria-hidden="true"></i> Start Recording
                                          Now</a> */}

                                      {renderStartButton()}  
                                     
                                      {renderUploadButton()}  
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          {/* <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="rec_img">
                            <img className="img-w" src="/newsite/assest/images/screen-recorder2.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="screen-head">
                            <h2>Screen record from anywhere.</h2>
                            <p>The screen-recording programs included in the bundle enable you to capture everything from
                                desktop
                                activity and program use to the latest video games in Full HD or 4K.</p>
                            <div className="screen-btn mt-22">
                                <a href=""> <i className="fa fa-play-circle" aria-hidden="true"></i> Start Recording Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        {/*<section className="mb-80">
        <div className="container">
            <div className="work_head">
                <h2>How Our Screen & Camera Recorder Works</h2>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <div className="online-pos">
                        <div className=" online-flex">
                            <div className="screen_num mt-45 text-c">
                                <span>1</span>
                            </div>
                            <div className="online-para screen_text screen_tl">
                                <h2 className="text-c">Find screen & camera recording in the sidebar.</h2>

                            </div>
                        </div>
                        <div className="online-flex">
                            <div className="screen_num num_mt text-c">
                                <span>2</span>
                            </div>
                            <div className="online-para2 screen_text text-m">
                                <h2 className="text-c">Select your camera & mic, then hit the record button.</h2>

                            </div>
                        </div>
                        <div className="online-flex">
                            <div className="screen_num num_mt text-c">
                                <span>3</span>
                            </div>
                            <div className="online-para3 screen_text text-m">
                                <h2 className="text-c">Choose a tab, window, or screen to record.</h2>

                            </div>
                        </div>
                        <div className="online-flex">
                            <div className="screen_num num_mt text-c">
                                <span>4</span>
                            </div>
                            <div className="online-para3 screen_text text-m">
                                <h2 className="text-c">Drag & drop your camera & screen recordings into
                                    the timeline to edit.</h2>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="work_img">
                        <img src="/newsite/assest/images/work_rech.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section>

     <Faq/>*/}

     <section className="ready-rec-bg mt-40">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="capture-head">
                  <h2>Grabar Video</h2>
                    {/* <p>Sign Up with Pine Recorder </p> */}
                    <div className="ready-rec">
                    <Link to={'/register'}><i className="fa fa-circle" aria-hidden="true"></i> Grabar un video ahora</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
     <Footer/>
          
       </>
    );
}

export default Recording;